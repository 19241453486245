.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}

.otp-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.otp-btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
  button {
    width: 30%;
  }
}

.body-text {
  text-align: center;
}
