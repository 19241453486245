@import "../../../assets/scss/customVariables";

.kloo-radio {
  .ant-radio-inner {
    border-width: 2px;
    border-color: tint($txt-color, 50%);
  }

  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner {
    border-color: $primary-color;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: $brdcolSecondary;
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: $brdcolSecondary;
      background-color: $brdcolSecondary;
      &::after {
        background-color: $primary-color;
      }
    }

    &:hover {
      .ant-radio-inner {
        border-color: $brdcolSecondary;
      }
    }
  }

  .ant-radio-disabled:hover {
    .ant-radio-inner {
      border-color: tint($txt-color, 40%);
    }
  }

  .ant-radio-disabled .ant-radio-inner {
    border-color: tint($txt-color, 70%) !important;
    background-color: tint($txt-color, 95%);
    &::after {
      background-color: tint($txt-color, 70%);
    }
  }

  .ant-radio-disabled.ant-radio-checked {
    .ant-radio-inner {
      border-color: tint($brdcolSecondary, 50%);
      background-color: tint($brdcolSecondary, 50%);
      &::after {
        background-color: tint($primary-color, 45%);
      }
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
  }
}
