@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.close-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    @include xl {
      width: 1200px !important;
    }
  }
  .close-wrapper {
    .pdf-wrapper {
      height: calc(100% - 60px);
    }
  }

  .ant-card-bordered.pdf-view-container {
    border-width: 2px;
  }

  .common_table {
    border: solid 1px $grey;
    border-radius: 4px;
    padding: 0 16px 16px 16px;
    height: auto;
    max-height: calc(100vh - 278px);
    th {
      &:first-child {
        width: 12% !important;
        @include customMaxWidthBreakpoint(1000px) {
          width: 18% !important;
        }
      }
    }
  }
  .purchase-drawer-footer {
    background-color: $lightgrey;
    position: absolute;
    z-index: 100;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
  }
}
