@use "sass:color";
@import "../../../assets/scss/customVariables";

// <-------------- Custom buttons --------------->
.kloo-button {
  // base button styling with prefix/suffix icons
  display: flex !important;
  text-decoration: none !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  align-items: center !important;
  padding: 3px 15px !important;
  line-height: 24px !important;
  justify-content: center !important;
  min-height: 32px !important;
  cursor: pointer !important;
  font-size: 14px !important;
  height: auto !important;
  transition: 0.3s all;
  border-color: transparent !important;
  box-shadow: none !important;
  span {
    font-size: 14px !important;
    line-height: 22px !important;
  }
  i {
    font-weight: 400 !important;
    font-size: 24px !important;
  }
  i + span {
    margin-left: 8px !important;
  }
  span + i {
    margin-left: 8px !important;
  }
  &:disabled {
    cursor: not-allowed !important;
  }
  // button style for filled background
  @each $buttonType, $buttonThemeColor in $themeAccents {
    &.filled-white-text-#{$buttonType} {
      background: #{$buttonThemeColor} !important;
      border: 1px solid #{$buttonThemeColor} !important;
      color: $white !important;
      i {
        color: inherit !important;
      }
      span {
        color: inherit !important;
      }
      &:hover {
        background: shade($buttonThemeColor, 10%) !important;
        border: 1px solid #{$buttonThemeColor} !important;
        i {
          color: inherit !important;
        }
      }
      &:active {
        background: shade($buttonThemeColor, 30%) !important;
        border: 1px solid #{$buttonThemeColor} !important;
        i {
          color: inherit !important;
        }
      }
      &:focus {
        background: #{$buttonThemeColor} !important;
        border: 1px solid #{$buttonThemeColor} !important;
        i {
          color: inherit !important;
        }
      }
      &:disabled {
        background: tint($txt-color, 95%) !important;
        border: 1px solid tint($txt-color, 95%) !important;
        color: tint($txt-color, 60%) !important;
      }
    }
    &.filled-#{$buttonType} {
      background: #{$buttonThemeColor} !important;
      border: 1px solid #{$buttonThemeColor} !important;
      color: $primary-color !important;
      i {
        color: inherit !important;
      }
      span {
        color: inherit !important;
      }
      &:hover {
        background: shade($buttonThemeColor, 10%) !important;
        border: 1px solid #{$buttonThemeColor} !important;
        i {
          color: inherit !important;
        }
      }
      &:active {
        background: shade($buttonThemeColor, 30%) !important;
        border: 1px solid #{$buttonThemeColor} !important;
        i {
          color: inherit !important;
        }
      }
      &:focus {
        background: #{$buttonThemeColor} !important;
        border: 1px solid $primary-color !important;
        i {
          color: inherit !important;
        }
      }
      &:disabled {
        background: tint($txt-color, 95%) !important;
        border: 1px solid tint($txt-color, 95%) !important;
        color: tint($txt-color, 60%) !important;
      }
    }
    // button style for hollow background with border having prefix/suffix icons
    &.hollow-#{$buttonType} {
      color: #{$buttonThemeColor} !important;
      border: 1px solid #{$buttonThemeColor} !important;
      background: $white !important;
      i {
        color: inherit !important;
      }
      span {
        color: inherit !important;
      }
      &:hover {
        background: tint($txt-color, 95%) !important;
        border: 1px solid #{$buttonThemeColor} !important;
        i {
          color: inherit !important;
        }
      }
      &:focus {
        background: $white !important;
        border: 1px solid #{$buttonThemeColor} !important;
        i {
          color: inherit !important;
        }
      }
      &:active {
        background: tint($txt-color, 90%) !important;
        border: 1px solid #{$buttonThemeColor} !important;
        i {
          color: inherit !important;
        }
      }
      &:disabled {
        background: $white !important;
        border: 1px solid tint($buttonThemeColor, 60%) !important;
        color: tint($buttonThemeColor, 60%) !important;
      }
    }
    // button style for transparent background and text color having prefix/suffix icons. borders will appear on hover
    &.text-#{$buttonType} {
      color: #{$buttonThemeColor} !important;
      border: 1px solid transparent !important;
      background: transparent !important;
      i {
        color: inherit !important;
      }
      span {
        color: inherit !important;
      }
      &:hover {
        background: #eff1f5 !important;
        border: 1px solid #eff1f5 !important;
        i {
          color: inherit !important;
        }
      }
      &:active {
        background: #e0e4ea !important;
        border: 1px solid #e0e4ea !important;
        i {
          color: inherit !important;
        }
      }
      &:focus {
        background: $white !important;
        border: 1px solid #{$buttonThemeColor} !important;
        i {
          color: inherit !important;
        }
      }
      &:disabled {
        background: $white !important;
        color: tint($buttonThemeColor, 60%) !important;
        &:hover {
          border: 1px solid transparent !important;
        }
      }
    }
    // button style for button with only icon
    &.only-icon-#{$buttonType} {
      padding: 2px 2px !important;
      border-radius: 0 !important;
      background: transparent !important;
      color: #{$buttonThemeColor} !important;
      border: 1px solid transparent !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      line-height: 22px !important;
      min-height: 30px !important;
      i {
        font-size: 24px !important;
        color: inherit !important;
      }
      span {
        color: inherit !important;
      }
      &:hover,
      &:focus,
      &:active {
        border: 1px solid transparent !important;
        i {
          color: inherit !important;
        }
      }
      &:disabled {
        color: tint($buttonThemeColor, 60%) !important;
      }
    }
    &.ai-btn-#{$buttonType} {
      color: #{$buttonThemeColor} !important;
      border: 2px solid transparent !important;
      background: linear-gradient(#fff, #fff) padding-box,
        linear-gradient(60deg, #0eae88, #1350c7) border-box;
      box-shadow: 0px 0px 0px 3px #b0f1e2 !important;
      padding: 3px 10px !important;
      gap: 8px;
      i {
        color: inherit !important;
      }
      span {
        color: inherit !important;
      }
      &:hover {
        border: 2px solid transparent !important;
        background: linear-gradient(#e0e4ea, #e0e4ea) padding-box,
          linear-gradient(60deg, #0eae88, #1350c7) border-box;
        i {
          color: inherit !important;
        }
      }
      &:active {
        border: 2px solid transparent !important;
        background: linear-gradient(#e0e4ea, #e0e4ea) padding-box,
          linear-gradient(60deg, #0eae88, #1350c7) border-box;
        i {
          color: inherit !important;
        }
      }
      &:focus {
        border: 2px solid transparent !important;
        background: linear-gradient(#e0e4ea, #e0e4ea) padding-box,
          linear-gradient(60deg, #0eae88, #1350c7) border-box;
        i {
          color: inherit !important;
        }
      }
      &:disabled {
        background: $white !important;
        color: tint($buttonThemeColor, 60%) !important;
        &:hover {
          border: 2px solid transparent !important;
        }
      }
    }
  }
  &.small {
    padding: 0 15px !important;
    min-height: 24px !important;
    border-radius: 4px !important;
  }
  &.large {
    padding: 7px 15px !important;
    min-height: 40px !important;
  }
}

// <-------------- Custom links --------------->
.kloo-link {
  // link style for hollow background with border with prefix/suffix icons
  display: flex !important;
  text-decoration: none !important;
  font-weight: 600 !important;
  align-items: center !important;
  justify-content: flex-start !important;
  cursor: pointer !important;
  font-size: 14px !important;
  padding: 0 !important;
  background-color: transparent !important;
  background: transparent !important;
  border: 0 !important;
  span {
    font-size: 14px !important;
  }
  i {
    font-weight: 400 !important;
    font-size: 18px !important;
  }
  i + span {
    margin-left: 8px !important;
  }
  span + i {
    margin-left: 8px !important;
  }
  &:disabled {
    cursor: not-allowed !important;
  }
  @each $linkType, $linkThemeColor in $themeAccents {
    &.#{$linkType} {
      color: #{$linkThemeColor} !important;
      i {
        color: inherit !important;
      }
      span {
        color: inherit !important;
      }
      &:hover,
      &:focus,
      &:active {
        color: #{$linkThemeColor} !important;
        i {
          color: inherit !important;
        }
      }
      &:disabled {
        color: tint($linkThemeColor, 60%) !important;
      }
    }
  }
}
