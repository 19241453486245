@import "../../../assets/scss/_customVariables.scss";

.dot {
  content: " ";
  left: 15px;
  top: 50%;
  &.inactive {
    background-color: tint($txt-color, 95%);
  }
  &.active {
    background-color: $brdcolSecondary;
  }
  height: 5px;
  width: 5px;
  border-radius: 50px;
}

.filter-multi {
  .menu-submenu {
    max-height: 300px;
    overflow: auto;
  }

  .kid-item-body {
    cursor: pointer;
    &:hover {
      background-color: tint($brdcolSecondary, 90%);
    }
  }
  .ant-popover-inner-content {
    padding: 8px 0px;
  }
  .filterButton {
    min-height: 42px;
    .filter-label {
      color: blue;
      font-weight: normal;
    }
    .filter-icon {
      color: blue !important;
    }
  }

  .kid-item-cnt {
    color: black;
    height: 30px;
    color: inherit;
    min-width: 232px;
    cursor: pointer;
    .kid-checkbox {
      margin-right: 0.5em;
    }
  }
  .kid-item-cnt-child {
    color: black;
    height: 30px;
    color: inherit;
    min-width: 206px;
    max-width: 300px;
    .kid-checkbox {
      margin-right: 0.5em;
    }
  }

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: $txt-color;
  }
}

.kloo-multi-level-filter-popup {
  position: fixed;

  &.ant-zoom-big-leave.ant-zoom-big-leave-active {
    animation: 0;
    opacity: 0;
  }
}

.ant-slider-track {
  background-color: #66ddd6 !important;
}

.ant-slider-handle {
  border: solid 2px #66ddd6 !important;
}

.inactive-label {
  color: #b3b7bb !important;
}

.filter-slider-tooltip {
  .ant-tooltip-inner {
    height: 60px;
    width: 109px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.filter-switch {
  background-color: $primary-color !important;
  border: 2px solid $primary-color !important;
  .ant-switch-handle::before {
    background-color: $brdcolSecondary;
  }
  .ant-switch-handle::before {
    background-color: $brdcolSecondary !important;
  }
}
