@import "../../../assets/scss/customVariables";

.assign-workflow-modal {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .workflow-selected-step {
    display: flex;
    .workflow-total-step {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      padding-right: 32px;
    }
  }
  .workflow-content-section {
    overflow: auto;
    max-height: 65vh;
    .workflow-selected-step {
      display: flex;
      .workflow-total-step {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding-right: 32px;
      }
    }
  }
  .workflow-step-data {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    .inside-workflow-step-data {
      width: 155px;
    }
  }
  .workflow-approver-name-arrow {
    align-items: center;
    display: flex;
    margin-left: 1rem;
    margin-right: 1rem;
    white-space: nowrap;
    font-size: x-large;
  }
  .workflow-approver-name-txt.in-assign-drawer {
    font-size: 0.8rem;
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 20px;
    &::before {
      display: block;
      content: "";
    }
  }
  .workflow-approver-over-upto-txt {
    color: #858585;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 20px;
  }
  .workflow-approver-value-txt {
    color: #858585;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 20px;
  }
  .approver-name {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  .assign-workflow-modal-btns {
    display: flex;
    justify-content: center;
    .cancel-button {
      width: 261px;
      margin-left: 32px;
    }
    .confirm-button {
      width: 261px;
    }
  }
  .approval-path-border {
    border: 2px solid $grey;
    border-radius: 4px;
  }
}

.preview-workflow {
  border: none !important;
  margin-bottom: 0px !important;
}
