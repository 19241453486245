.kloo-box {
  display: flex;

  &.direction-row {
    flex-direction: row;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.direction-row-reverse {
    flex-direction: row-reverse;
  }

  &.direction-column-reverse {
    flex-direction: column-reverse;
  }

  &.align-content-stretch {
    align-content: stretch;
  }

  &.align-content-center {
    align-content: center;
  }

  &.align-content-space-around {
    align-content: space-around;
  }

  &.align-content-space-evenly {
    align-content: space-evenly;
  }

  &.align-content-space-between {
    align-content: space-between;
  }

  &.align-content-flex-start {
    align-content: flex-start;
  }

  &.align-content-flex-end {
    align-content: flex-end;
  }

  &.justify-content-center {
    justify-content: center;
  }

  &.justify-content-space-between {
    justify-content: space-between;
  }

  &.justify-content-space-around {
    justify-content: space-around;
  }

  &.justify-content-space-evenly {
    justify-content: space-evenly;
  }

  &.justify-content-flex-start {
    justify-content: flex-start;
  }

  &.justify-content-flex-end {
    justify-content: flex-end;
  }

  &.align-item-stretch {
    align-items: stretch;
  }

  &.align-item-baseline {
    align-items: baseline;
  }

  &.align-item-center {
    align-items: center;
  }

  &.align-item-space-between {
    align-items: space-between;
  }

  &.align-item-flex-start {
    align-items: flex-start;
  }

  &.align-item-flex-end {
    align-items: flex-end;
  }

  &.align-self-center {
    align-self: center;
  }

  &.flex-wrap {
    flex-wrap: wrap;
  }

  &.flex-nowrap {
    flex-wrap: nowrap;
  }

  &.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}
