@import "../../../assets/scss/customVariables";
$alert: (
  success: #66ddd6,
  info: #0095fa,
  warning: #ffbc00,
  error: #ff0055,
);

.kloo-alert {
  color: $txt-color !important;
  padding: 8px 12px !important;
  border-radius: 4px !important;
  @each $alertType, $alertColor in $alert {
    &.ant-alert-#{$alertType} {
      background-color: tint($alertColor, 90%) !important;
      border: 1px solid tint($alertColor, 70%) !important;
      .ant-alert-icon {
        color: $alertColor !important;
        font-size: 20px !important;
      }
    }
  }
  .ant-alert-content {
    .ant-alert-message {
      color: $txt-color !important;
      font-weight: 400 !important;
    }
  }
  &.ant-alert-with-description {
    .ant-alert-icon {
      margin-top: 3px !important;
    }
  }
  &.ant-alert-banner {
    border-radius: 0 !important;
    border: 0 !important;
  }
  .ant-alert-close-icon {
    i {
      font-size: 18px !important;
    }
  }
}
