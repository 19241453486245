// .kloo-log-bg {
//   width: 100%;
//   position: absolute;
//   height: calc(100% - 70px);

//   &::before {
//     content: "";
//     width: 500px;
//     height: 500px;
//     position: absolute;
//     left: calc(50% - 190px);
//     top: 50%;
//     transform: translate(-50%, -50%);
//     background-image: url("../../../assets/images/bg-logo.png");
//     background-repeat: no-repeat;
//     background-position: center center;
//     z-index: -1;
//   }
// }

// .img-container {
//   width: 100%;
//   position: absolute;
//   height: calc(100% - 70px);
// }

// .child-container {
//   min-height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";
.kloo-log-bg {
  display: flex;
  min-height: 100vh;
  @include lg {
    min-height: auto;
    height: 100vh;
  }

  &-left {
    background-color: $primary-color;
    display: none;
    position: relative;
    flex-basis: 40%;
    @include lg {
      display: flex;
    }
  }

  &-logo-container {
    max-width: 300px;
    padding: 0 25px;
    position: relative;
    z-index: 2;
  }
  &-logo {
    max-width: 100%;
  }

  &-right {
    min-height: 100vh;
    overflow: auto;
    flex-basis: 100%;
    @include lg {
      flex-basis: 60%;
    }
  }
  &-top-right-corner {
    position: absolute;
    top: 0;
    left: 0;
  }
  &-bottom-left-corner {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
