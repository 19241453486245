@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.download-purchase-order-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    @include xl {
      width: 650px !important;
    }
  }

  &.refactored-version {
    .ant-drawer-content-wrapper {
      @include xl {
        width: 1200px !important;
      }
    }

    .ant-drawer-body {
      padding-bottom: 0px;
    }

    .purchase-drawer-footer-refactored {
      background-color: $lightgrey;
    }

    .po-details-scroll-view {
      height: calc(100vh - 162px);
      overflow-y: auto;

      &.without-cta {
        height: calc(100vh - 92px);
      }
    }

    &.expanded-version {
      .ant-drawer-content-wrapper {
        @include xl {
          width: calc(100% - 15px) !important;
        }
      }
      .po-details-scroll-view {
        height: calc(100vh - 235px);
        overflow-y: auto;

        &.with-close-po-cta {
          height: calc(100vh - 282px);
        }

        &.without-cta {
          height: calc(100vh - 165px);
        }
      }
      iframe {
        height: calc(100vh - 302px) !important;
      }
    }
  }

  iframe {
    height: calc(100vh - 230px);
    width: 100%;
    border: 0;
  }

  .purchase-drawer-footer {
    background-color: $lightgrey;
    position: absolute;
    z-index: 100;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0px 15px 15px 5px;
  }
}
