@import "customVariables";
$themeblue: #2712ff;
$brandcolor: #1127ff;
$optionalField: #d9d9d9;

// form
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.ant-select-arrow {
  svg {
    fill: #a8a7a7;
  }
}

// Input
.commonInput,
.commonDatePicker,
.commonDatePicker:hover,
.commonDatePicker:focus,
.commonInput:hover,
.commonInput:focus {
  border: 2px solid $themeblue;
  padding: 13px 14px;
  border-radius: 4px;
  width: 100%;

  &.optional-field {
    border-color: $optionalField;
  }
}

.ant-input[disabled] {
  // border: 2px solid $themeblue;
  color: #000000;
}

.ant-form-item-label {
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    // line-height: 15px;
    color: #000000;
  }
}

.ant-form-item-control {
  ::placeholder {
    font-style: normal;
    // font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.3;
  }

  .ant-picker-input {
    ::placeholder {
      font-style: normal;
      // font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      opacity: 0.3;
    }
  }

  .ant-picker-suffix {
    svg {
      fill: $brandcolor;
    }
  }
}
.commonInputNumber {
  .ant-input-number-input {
    height: auto;
  }
}

.kloo-input {
  border: 2px solid tint($txt-color, 50%) !important;
  padding: 7px 14px !important;
  border-radius: 4px !important;
  color: $txt-color !important;
  font-weight: 500 !important;
  width: 100% !important;
  font-size: 14px !important;
  &.ant-input-password {
    font-size: 14px !important;
  }

  &:not(.kloo-input-textarea) {
    height: 40px !important;
  }
  &::placeholder {
    color: tint($txt-color, 50%) !important;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: tint($txt-color, 50%) !important;
  }

  &::-ms-input-placeholder {
    color: tint($txt-color, 50%) !important;
  }
  &:hover {
    background-color: tint($txt-color, 95%) !important;
  }
  &:focus {
    border: 2px solid tint($brdcolSecondary, 50%) !important;
    background-color: $white !important;
    box-shadow: none !important;
  }
  &.ant-input-status-error {
    border: 2px solid $destructive !important;
  }
  &.optional-field {
  }
  &:disabled {
    background-color: tint($txt-color, 95%) !important;
    border: 2px solid tint($txt-color, 70%) !important;
    color: tint($txt-color, 70%) !important;
    &::placeholder {
      color: tint($txt-color, 70%) !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: tint($txt-color, 70%) !important;
    }

    &::-ms-input-placeholder {
      color: tint($txt-color, 70%) !important;
    }
  }
  &.ant-input-affix-wrapper {
    input {
      font-weight: 500 !important;
      &::placeholder {
        color: tint($txt-color, 50%) !important;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: tint($txt-color, 50%) !important;
      }

      &::-ms-input-placeholder {
        color: tint($txt-color, 50%) !important;
      }
    }
    &:hover {
      input {
        background-color: tint($txt-color, 95%) !important;
        &::placeholder {
          color: tint($txt-color, 50%) !important;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: tint($txt-color, 50%) !important;
        }

        &::-ms-input-placeholder {
          color: tint($txt-color, 50%) !important;
        }
      }
    }
    &.ant-input-affix-wrapper-focused {
      border: 2px solid tint($brdcolSecondary, 50%) !important;
      background-color: $white !important;
      box-shadow: none !important;
      input {
        background-color: $white !important;
      }
    }
    &.ant-input-affix-wrapper-status-error {
      border: 2px solid $destructive !important;
      .ant-input-suffix,
      .ant-input-prefix {
        color: $destructive !important;
        opacity: 1; /* required on iOS */
      }
    }
    &.ant-input-affix-wrapper-disabled {
      input {
        color: tint($txt-color, 70%) !important;
        -webkit-text-fill-color: tint($txt-color, 70%) !important;
        opacity: 1; /* required on iOS */
      }
      .ant-input-suffix,
      .ant-input-prefix {
        color: tint($txt-color, 70%) !important;
        -webkit-text-fill-color: tint($txt-color, 70%) !important;
        opacity: 1; /* required on iOS */
      }
    }
  }
  &.large {
    height: 40px !important;
  }

  /* Chrome, Safari, Edge, Opera */
  &.hide-controls[type="number"]::-webkit-outer-spin-button,
  &.hide-controls[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  [type="number"] {
    -moz-appearance: textfield;
  }
}

.ant-form-item {
  margin-bottom: 20px !important;
}
