.add-mapping-container {
  width: 410px;
  background-color: #fff;
  padding: 10px;
  background-clip: padding-box;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  .ant-input-affix-wrapper {
    height: 48px;
    border-radius: 8px;
  }
  .ant-dropdown-menu {
    margin-top: 0px !important;
    box-shadow: none;
    max-height: 350px;
    overflow: auto;
    margin-bottom: 10px;
    .ant-dropdown-menu-item {
      &:hover {
        background-color: #fff !important;
      }
    }
  }
}
.drop-down-button {
  margin-top: 16px;
  width: 187px;
}

.checked-inner-change {
  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: #66ddd6 !important;
      border: solid 2px #66ddd6;
    }
    .ant-checkbox-inner::after {
      content: "-";
      opacity: 1;
      position: absolute;
      top: 1px;
      align-items: center;
      border: unset !important;
      color: #000;
      transform: rotateZ(0deg);
      padding-right: 0px;
      margin-right: 1px;
    }
  }
}
