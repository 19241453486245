@import "../../../assets/scss/App.scss";
@import "../../../assets/scss/customVariables";
.users-permission-container {
  .approver-list-card {
    border-radius: 4px;
    border: 1px solid $grey;
  }

  .select-wrapper-class {
    width: 410px;
  }
}
