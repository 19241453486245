// Color Pallette
$white: #ffffff;
$links: #858585;
$disabled-primary: #97c1ff;
$disabled-primary-border: #7ab0ff;
$black: #000;
// $brandcolor: #1127ff;
// $brandcolorhover: #0052f7;
// $brandcolorpressed: #004191;
// $brandcolordisabled: #63a5f7;
$themeblue: #2712ff;
$red: #cc0044;
$amber: #ffbf00;
$orange: #ff5e00;
$success: #249f5d;
$successpressed: #186b3f;
$successdisabled: #b0d4c0;
$warning: #ffbc00;

$destructive: #cc0044;
$destructivehover: #ff0000;
$destructivepressed: #ce0000;
$destructivedisabled: #f32d2d;
$brdcolSecondary: #66ddd6;
$brdcolSecondaryhover: #66ddd6;
$brdcolSecondarypressed: #52b1ab;
$brdcolSecondarydisabled: #eea494;
$optionalField: #d9d9d9;
$lightgrey: #f7f7f9;
$txt-color: #000e1c;
$primary-color: #001b37;
$grey: #e0e2e7;
$cyanshade: #0095fa;
$box-shadow: #00000026;
$condition-field-bg: #f0fcfb;
$card-border: #eaeaea;
$flash-color: #fff8e6;
$dragging-color: #add8e6;
$drawer-footer-bg: #eff1f5;
$kloo-disabled-bg: #f5f5f5;
$invalid-icon-color: #ffb82e;
$expired-icon-color: #ee6c4d;
$card-border-color: #9ca1ad;
$xero-card-border: #13b5ea;
$xero-card-bg: #13b5ea33;
$xero-card-text: #00a1d5;
$sage-card-border: #00dc00;
$sage-card-bg: #00dc0033;
$sage-card-text: #00c900;
$drawer-collapse-icon-color: #0000000f;
$collapsible-drawer-border-color: #d9d9d9;

// $grey1: #33495f;
$grey2: #667687;
// $grey3: #99a4af;
// $grey4: #ccd1d7;
$darkGrey: #80878e;
// $grey6: #f2f3f4;
// $disabled-grey: #999fa4;
$txt-link: #0077c8;

.txt-blue {
  color: $brdcolSecondary !important;
}

/*Fonts*/
$text-font-family: "Roboto", sans-serif;

$notification-success-background-color: #f0fcfb;
$notification-info-background-color: #e6f4ff;
$notification-error-background-color: #ffe6ee;

$notification-info-border-color: #0095fa;
$notification-error-border-color: #ff0055;

/*SCSS Dynamic Helper Class Functions Like Padding, Margin and Width*/
$position: (
  top: "t",
  right: "r",
  left: "l",
  bottom: "b",
);

$unit: (
  rem: "rem",
  per: "%",
  px: "px",
);

$themeColors: (
  "primary": #001b37,
  "secondary": #66ddd6,
  "green": #249f5d,
  "red": #f32d2d,
  "grey": #232325,
);

$themeAccents: (
  "primary": $brdcolSecondary,
  "secondary": $primary-color,
  "tertiary": $amber,
  "success": $success,
  "destructive": $destructive,
  "black": $black,
  "white": $white,
  "link": $txt-link,
  "destructive-disabled": $destructivedisabled,
);

// Slightly lighten a color
@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

// Slightly darken a color
@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}
