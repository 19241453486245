@import "../../assets/scss/customVariables";
.workflow-form {
  display: flex;
  justify-content: center;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .step-card {
    width: 100%;
    padding: 24px 32px 16px 32px;
    box-shadow: 1px 2px 6px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    .card-close-icon {
      color: #000;
      font-size: 24px;
      width: 24px;
      position: absolute;
      top: 24px;
      right: 32px;
      cursor: pointer;
    }
    .ant-card-body {
      padding: 0px;
    }
    .step-type-select {
      width: 402px;
    }
    .approver-select {
      width: 175px;
    }
    .approver-select-full-width {
      width: 100%;
    }
    .approver-amount-input {
      width: 125px; // 158px;
    }
    .w-158px {
      width: 158px;
    }
    .form-title {
      text-align: center;
    }
    .close-icon {
      color: $destructive;
      font-size: 24px;
      width: 24px;
      display: flex;
      align-self: flex-start;
      margin-top: 14px;
    }
  }

  .step-card-smaller {
    max-width: 618px;
  }

  .step-card-wider {
    max-width: 85%;
    .inputs-child-container,
    .step-type-select {
      width: 702px;
    }
  }

  .inputs-container {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .inputs-child-container {
    display: flex;
    align-items: baseline;
    width: 402px;
    justify-content: space-between;
    .amount-label {
      width: 64px;
      text-align: center;
    }
    .amount-label-before-custom-value {
      width: 150px;
      text-align: center;
    }
    .amount-label-after-custom-value {
      width: 180px;
      text-align: center;
    }
  }

  .work-flow-name-container-wrap {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .work-flow-name-container {
    display: flex;
    align-items: baseline;
    max-width: 618px;
    width: 100%;
    justify-content: space-between;
    gap: 16px;
    .name-input {
      flex-grow: 1;
    }
  }

  .btn-container {
    display: flex;
    justify-content: center;
    gap: 32px;
    width: 100%;
    max-width: 618px;
    button {
      flex: 1;
    }
  }

  .add-step-container {
    align-self: center;
    .add-step-btn {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(100px, -50%);
    }
    .add-step-btn-icon {
      font-size: 38px;
      color: #ee6c4d;
    }
  }

  .step-carousel-container {
    max-width: 100%;
  }

  .ant-form-item-explain-error {
    margin-bottom: 0px;
  }
  .commonInput,
  .commonDatePicker,
  .commonDatePicker:hover,
  .commonDatePicker:focus,
  .commonInput:hover,
  .commonInput:focus {
    // padding: 0px;
  }

  .warn-box {
    width: 618px;
    text-align: center;
    margin-top: 16px;
  }
}
