@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.review-purchase-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    @include xl {
      width: 650px !important;
    }
  }
  .close-wrapper {
    .close-po-details-View {
      height: calc(100% - 70px);
    }
    .pdf-wrapper {
      height: calc(100% - 70px);
      padding: 0 24px;
    }
  }

  &.refactored-version {
    .ant-drawer-content-wrapper {
      @include xl {
        width: 1200px !important;
      }
    }

    .ant-drawer-body {
      padding-bottom: 0px;
      padding-right: 0px;
    }

    .purchase-drawer-footer-refactored {
      background-color: $lightgrey;
    }

    .po-details-scroll-view {
      height: calc(100vh - 162px);
      overflow-y: auto;

      &.without-cta {
        height: calc(100vh - 92px);
      }
    }

    &.expanded-version {
      .ant-drawer-content-wrapper {
        @include xl {
          width: calc(100% - 15px) !important;
        }
      }
      .po-details-scroll-view {
        height: calc(100vh - 235px);
        overflow-y: auto;

        &.with-close-po-cta {
          height: calc(100vh - 282px);
        }

        &.without-cta {
          height: calc(100vh - 165px);
        }
      }
    }
  }

  .ant-card-bordered.pdf-view-container {
    border-width: 2px;
  }

  Button.remove-list {
    float: right;
  }

  .common_table {
    border: solid 1px $grey;
    border-radius: 4px;
    padding: 0 16px 16px 16px;
    height: auto;
  }

  iframe {
    height: calc(100vh - 230px);
    width: 100%;
    border: 0;
  }
  .purchase-drawer-footer {
    background-color: $lightgrey;
    z-index: 100;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
  }
}
