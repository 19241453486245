@import "../../../assets/scss/customVariables";

.workflow-section {
  border: 2px solid $grey;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 32px;
  overflow-y: auto;
  max-height: 500px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(228, 228, 228);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 20px;
  }

  .check-circle-color {
    color: $optionalField;
    font-size: 26px;
  }
  .add-last-item-width {
    width: 29px;
  }

  .vertical-line {
    border-left: 2px solid $grey;
    color: $optionalField;
    height: 3em;
    margin: 5px 0px 5px 0px;
  }

  .approver-path-name-txt {
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 20px;
    margin-top: 3px;
    align-self: flex-start;
    &::before {
      display: block;
      content: "";
    }
  }
}
