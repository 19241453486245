@import "../../../assets/scss/customVariables";
.tag-cnt {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.kloo-chip {
  display: flex !important;
  max-width: 100%;
  align-items: center !important;
  margin: 4px 8px 4px 0px !important;
  padding: 4px 15px !important;
  font-size: 14px !important;
  min-height: 32px !important;
  border: 0 !important;
  color: $primary-color !important;
  background: $brdcolSecondary !important;
  border-radius: 4px !important;
  .chip {
    font-weight: 400 !important;
    &::after {
      content: "";
      display: block;
    }
  }
  .close-icon {
    cursor: pointer;
    margin-left: 4px !important;
    font-size: 18px !important;
  }
}
