@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.po-list-table {
  .common_table {
    height: auto;
    margin-bottom: 0px;
    border: 1px solid;
  }
}

.assign-approval-drawer {
  .workflow-info-box {
    border: 2px solid #e0e2e7;
    border-radius: 4px;
    .ant-typography {
      opacity: 0.5;
    }
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
    @include lg {
      max-width: 1200px !important;
    }
  }

  .common_table {
    height: auto;
    border: solid 1px $grey;
    border-radius: 4px;
    padding: 0 16px 16px 16px;

    tbody {
      tr {
        cursor: default;
      }
    }

    th {
      &:first-child {
        width: 7% !important;
        @include md {
          width: 10% !important;
        }
      }
      &:nth-child(4) {
        width: 25%;
      }
      &:nth-child(5) {
        width: 5%;
      }
    }

    tbody {
      tr {
        th:last-child {
          background-color: $white;
        }
      }
    }
  }
}
