@import "../../../assets/scss/customVariables";

.common_table {
  position: relative;
  margin-bottom: 0px;
  padding: 0px 16px 0px 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  // height: calc(100vh - 257px);
  height: 100%;
  overflow: auto;

  thead {
    // position: sticky;
    // top: 0;
    // z-index: 99;

    tr {
      th {
        padding: 12px;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: $txt-color;
        border: 0px;
        background-color: $white;

        &::before {
          content: none !important;
        }
      }
    }
  }

  tbody {
    tr {
      cursor: pointer;

      td {
        padding: 10px 12px;
        background-color: rgba($primary-color, 0.05);
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $txt-color;
        overflow-wrap: unset;
        border: 0px;

        &::first-letter {
          text-transform: uppercase;
        }

        &:first-child {
          border-radius: 4px 0 0 4px;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }

      &:nth-child(2n) {
        td {
          background-color: rgba($white, 0);
        }
      }
    }
  }

  .ant-table-empty {
    tr.ant-table-placeholder {
      cursor: default;
      td {
        padding-top: 10%;
        background: $white !important;
      }
    }
  }

  .table-border {
    border: 1px solid #e0e2e7;
    border-radius: 4px;
  }
}

.common_table_header {
  position: relative;
  padding: 0px 16px 0px 16px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  // height: calc(100vh - 257px);
  height: auto;

  .dataTable {
    width: 100%;
    text-align: left;
    table-layout: fixed;

    thead {
      background-color: #fff;
      tr {
        th {
          padding: 12px;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: $txt-color;
          background-color: $white;
        }
        th.sticky-column {
          position: sticky;
          left: 0;
          z-index: 1;
          min-width: 200px;
        }
      }
    }
  }

  .table-border {
    border: 1px solid #e0e2e7;
    border-radius: 4px;
  }
}

.kloo-layout-table-header {
  background-color: #fff;
}

.disabled-row {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.common_table .ant-table-tbody > tr.ant-table-row:hover > td,
.common_table .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: rgba($brdcolSecondary, 0.1);
}
