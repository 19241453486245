@import "../../../assets/scss/customVariables";

.step-work-container {
  overflow-x: auto;
  width: 100%;
  height: auto;
  padding: 0 60px;
}

.description {
  color: $darkGrey !important;
}

.timer-label {
  .ant-progress-inner {
    height: 20px !important;
    width: 20px !important;
  }
  span {
    font-size: 10px !important;
  }
}
