// mixins to be used instead of media queries
@use "sass:map";

// breakpoints
$breakpoints: (
  "sm": 576px,
  "md": 768px,
  "lg": 992px,
  "xl": 1200px,
  "xxl": 1600px,
);

@mixin sm {
  @media (min-width: map.get($breakpoints, "sm")) {
    @content;
  }
}

@mixin md {
  @media (min-width: map.get($breakpoints, "md")) {
    @content;
  }
}

@mixin lg {
  @media (min-width: map.get($breakpoints, "lg")) {
    @content;
  }
}

@mixin xl {
  @media (min-width: map.get($breakpoints, "xl")) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: map.get($breakpoints, "xxl")) {
    @content;
  }
}

// custom breakpoints: enter minimum and/or maximum width breakpoint
@mixin customMinWidthBreakpoint($breakpointMinWidth: 0) {
  @media (min-width: $breakpointMinWidth) {
    @content;
  }
}

@mixin customMaxWidthBreakpoint($breakpointMaxWidth: 0) {
  @media (max-width: $breakpointMaxWidth) {
    @content;
  }
}

@mixin customMinMaxWidthBreakpoint(
  $breakpointMinWidth: 0,
  $breakpointMaxWidth: 0
) {
  @media (min-width: $breakpointMinWidth) and (max-width: $breakpointMaxWidth) {
    @content;
  }
}
