@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.approval-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    @include xl {
      width: 1200px !important;
    }
  }

  .ant-card-bordered.pdf-view-container {
    border-width: 2px;
  }

  Button.remove-list {
    float: right;
  }

  .common_table {
    border: solid 1px $grey;
    border-radius: 4px;
    padding: 0 16px 16px 16px;
    height: auto;
    &.approval-reject-table {
      max-height: calc(100vh - 295px);
    }
    max-height: calc(100vh - 235px);
    // @include lg {
    //   height: calc(100vh - 257px);
    // }

    th {
      &:first-child {
        width: 10% !important;
        @include customMaxWidthBreakpoint(1000px) {
          width: 18% !important;
        }
      }
      &:nth-child(4) {
        width: 25%;
      }
      &:nth-child(5) {
        width: 5%;
      }
    }
  }
  .purchase-drawer-footer {
    background-color: $lightgrey;
    position: absolute;
    z-index: 100;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
  }
}
