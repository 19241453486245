@import "_customVariables";
@import "_mixins";

body {
  margin: 0;
  //   font-family: $font-OpenSans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-size: 12px;
  /* background: linear-gradient(180deg,rgba(234,242,255,.67),rgba(248,247,255,0)); */
  background: linear-gradient(
    180deg,
    rgba(250, 250, 252, 0.67),
    rgba(72, 72, 72, 0)
  );
  background-repeat: no-repeat;
  font-family: $text-font-family !important;
  color: $primary-color !important;
}

.kloo-font-weight {
  &-bold {
    font-weight: 700;
  }
  &-semibold {
    font-weight: 600;
  }
  &-regular {
    font-weight: 400;
  }
}

html,
body {
  height: 100%;
  overflow: hidden;
}

#root,
.App {
  height: 100%;
}

a {
  color: $txt-link;
  text-decoration: none;

  &:hover {
    color: $txt-link;
    text-decoration: underline;
  }
}

.ant-form-item-explain-error {
  font-size: 12px;
  margin-bottom: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.secondary-font {
  font-family: "Source Sans Pro", sans-serif;
}

.App-logo {
  height: 40px;
  width: auto;
  pointer-events: none;
  margin-top: 30px;
}

.form-custom {
  min-width: 460px;
  max-width: 460px;
  margin: 0 auto;
  border: 1px solid tint($primary-color, 80%);
  border-radius: 5px;
  padding: 25px 25px 30px;
  background-color: #fff;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  @include customMaxWidthBreakpoint(576px) {
    min-width: unset;
    max-width: unset;
    margin: 0 15px;
  }
}

.login-form-forgot {
  float: right;
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.ant-notification-notice {
  display: flex;
  align-items: center;

  .ant-notification-notice-close {
    position: unset;
    align-self: center;
    margin-left: 10px;
  }
}
// .ant-picker-status-error.ant-picker .material-icons {
//     color: #ff4d4f;
// }

// .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector .material-icons {
//     color: #ff4d4f;
// }
// .ant-popover-btn{
//     span{
//       z-index: -9;
//     }
//   }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

.kloo-notification {
  border-radius: 8px;
  border-left: 10px solid;
  box-shadow: 1px 2px 6px 2px rgba(0, 0, 0, 0.15);
  padding-left: 16px !important;
  .ant-notification-notice-icon {
    margin-left: 0 !important;
    margin-top: 2px !important;
  }
  .ant-notification-notice-with-icon {
    .ant-notification-notice-message {
      margin-left: 37px !important;
    }
    .ant-notification-notice-description {
      margin-left: 37px !important;
    }
  }

  &.ant-notification-notice {
    &-success {
      background: $notification-success-background-color;
      border-color: $brdcolSecondary;
    }

    &-info {
      background: $notification-info-background-color;
      border-color: $notification-info-border-color;
    }

    &-error {
      background: $notification-error-background-color;
      border-color: $notification-error-border-color;
    }
  }

  .ant-notification-notice-icon .kloo-material-icons {
    &.success {
      color: $brdcolSecondary;
    }

    &.info {
      color: $notification-info-border-color;
    }

    &.error {
      color: $notification-error-border-color;
    }
  }

  .ant-notification-notice-close-x {
    color: $primary-color;
  }
}

.ant-form-item {
  margin-bottom: 20px !important;
}

.ant-form-item-explain-error {
  margin-bottom: 16px !important;
  font-size: 12px !important;
  color: $destructive !important;
}

.ant-select-item-option-grouped {
  &.ant-select-item-option-active {
    background-color: tint($brdcolSecondary, 90%) !important;
    color: $txt-color !important;
  }
  &.ant-select-item-option-disabled {
    background-color: tint($brdcolSecondary, 80%) !important;
    color: $txt-color !important;
    font-weight: 500 !important;
  }
}

.creatSelectError {
  .kloo-dynamic-select__control {
    border: 2px solid $destructive !important;
  }
}

.kloo-dynamic-select__control {
  padding: 7px 14px !important;
  border: 2px solid tint($txt-color, 50%) !important;
  height: 40px !important;
  cursor: text !important;
  &:hover {
    background-color: #fff;
    background-color: tint($txt-color, 95%) !important;
  }
  &.kloo-dynamic-select__control--is-focused {
    border: 2px solid tint($brdcolSecondary, 50%) !important;
    box-shadow: none !important;
  }
  &.kloo-dynamic-select__control--is-disabled {
    background-color: tint($txt-color, 95%) !important;
    border: 2px solid tint($txt-color, 70%) !important;
    cursor: not-allowed !important;
    .kloo-dynamic-select__placeholder {
      color: tint($txt-color, 70%) !important;
    }
  }
  .kloo-dynamic-select__value-container {
    padding: 0 !important;
    .kloo-dynamic-select__placeholder {
      margin: 0 !important;
      color: tint($txt-color, 50%) !important;
      font-weight: 500 !important;
    }
    .kloo-dynamic-select__single-value {
      margin: 0 !important;
      color: $txt-color !important;
      font-weight: 500 !important;
      &.kloo-dynamic-select__single-value--is-disabled {
        color: tint($txt-color, 70%) !important;
      }
    }
  }
  .kloo-dynamic-select__input-container {
    height: 22px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .kloo-dynamic-select__indicators {
    display: none !important;
  }
}
.kloo-dynamic-select--is-disabled {
  cursor: not-allowed !important;
}

// Added code to match antd select css for react-selector option
.kloo-dynamic-select__option {
  height: 34px !important;
  cursor: pointer !important;
}

.kloo-dynamic-select__option--is-focused {
  background-color: tint($brdcolSecondary, 90%) !important;
  color: $txt-color !important;
}

.kloo-dynamic-select__option--is-selected {
  background-color: tint($brdcolSecondary, 80%) !important;
  color: $txt-color !important;
  font-weight: 500 !important;
}

.kloo-dynamic-select__menu {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;

  .kloo-dynamic-select__menu-list {
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #7f7f7f;
      border-radius: 20px;
    }
  }
}

.ant-tree-select-dropdown {
  padding: 4px 0 !important;
  .ant-select-tree-treenode {
    padding-bottom: 0 !important;
    .ant-select-tree-switcher-noop {
      display: none !important;
    }
    .ant-select-tree-node-content-wrapper {
      padding: 5px 12px !important;
      &:hover {
        background-color: tint($brdcolSecondary, 90%) !important;
        color: $txt-color !important;
      }
      &.ant-select-tree-node-selected {
        background-color: tint($brdcolSecondary, 80%) !important;
        color: $txt-color !important;
        font-weight: 500 !important;
      }
    }
  }
}

.ant-modal-content {
  border-radius: 4px !important;
}

.kloo-scrollbar {
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgb(228, 228, 228);
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(189, 189, 189);
    border-radius: 20px;
  }
}

.section-container {
  border: 1px solid $grey;
  border-radius: 4px;

  .section-header {
    height: 40px;
  }
}

.kloo-alert .ant-alert-icon.large-icon{
  font-size:32px !important
}
