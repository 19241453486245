@import "../../../assets//scss/customVariables";

.a-card-container {
  width: 406px;
  background: $white;
  border: 1px solid $card-border-color;
  border-radius: 4px;
  padding: 32px 32px;
  text-align: center;

  .a-icon {
    font-size: 41.6px;
    margin: 35px 0px;
  }
  .success {
    color: $success;
  }

  .fail {
    color: $destructivedisabled;
  }

  .invalid {
    color: $invalid-icon-color;
  }

  .expired {
    color: $expired-icon-color;
  }

  .circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid $success;
    margin: 35px 0px;
    .a-icon {
      font-size: 35px;
      margin: 0px;
    }
  }
}
