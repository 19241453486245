@import "../../assets/scss/customVariables";
@import "../../assets/scss/mixins";

.erp-card {
  border-radius: 4px;
  box-shadow: 1px 2px 6px 2px rgba(0, 0, 0, 0.15);
  background-color: $white;
  &.xero-card-design {
    border: 1px solid $xero-card-border;
    .card-text-section {
      background-color: $xero-card-bg;
      .card-text {
        color: $xero-card-text;
        font-weight: 500;
      }
    }
  }
  &.sage-card-design {
    border: 1px solid $sage-card-border;
    .card-text-section {
      background-color: $sage-card-bg;
      .card-text {
        color: $sage-card-text;
        font-weight: 500;
      }
    }
  }
  .erp-logo {
    width: auto;
  }
  .success-icon-color {
    color: $brdcolSecondary;
  }
  .warning-icon-color {
    color: $warning;
  }
  .progress-icon-color {
    color: $cyanshade;
  }
  .close-icon-color {
    color: $destructive;
  }
  .cloud-sync-icon {
    line-height: 1;
  }
}

.configure-integration-modal {
  .prefix-icon {
    border-radius: 4px 0px 0px 4px;
    border: 2px solid tint($txt-color, 50%);
    border-right: none !important;
    background-color: #f2f3f4;
  }
  .ant-form-item {
    margin-bottom: 0px !important;
  }
}
