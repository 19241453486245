@import "../../../../assets/scss/customVariables";

.common-currency-select {
  width: 100% !important;
  &:not(.ant-select-customize-input) .ant-select-selector {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    border-radius: 4px !important;
    border: 2px solid tint($txt-color, 50%) !important;
    height: 40px !important;
  }
  &:not(.ant-select-customize-input):not(.ant-select-customize-input)
    .ant-select-selection-search-input {
    height: 36px !important;
  }

  .ant-select-selection-item {
    color: $txt-color !important;
    font-weight: 500 !important;
    font-size: 14px !important;
  }
  .ant-select-selection-placeholder {
    font-weight: 500 !important;
    color: tint($txt-color, 50%) !important;
    font-size: 14px !important;
  }
  &.ant-select-focused {
    .ant-select-selector {
      box-shadow: none !important;
      border: 2px solid tint($brdcolSecondary, 50%) !important;
    }
  }
  &:hover {
    .ant-select-selector {
      background-color: tint($txt-color, 95%) !important;
    }
  }
  .ant-select-item-option {
    &.ant-select-item-option-active {
      background-color: tint($brdcolSecondary, 90%) !important;
      color: $txt-color !important;
    }
    &.ant-select-item-option-selected {
      background-color: tint($brdcolSecondary, 80%) !important;
      color: $txt-color !important;
    }
  }
  .ant-select-clear {
    right: 18px;
    background-color: tint($txt-color, 95%) !important;
    i {
      font-size: 14px;
    }
  }
  &.ant-select-disabled {
    .ant-select-selector {
      border: 2px solid tint($txt-color, 70%) !important;
      background-color: tint($txt-color, 95%) !important;
    }
    .ant-select-selection-placeholder {
      color: tint($txt-color, 70%) !important;
    }
    .ant-select-selection-item {
      color: tint($txt-color, 70%) !important;
    }
  }
  .currency-dropdown {
    .currency-search {
      padding: 8px !important;
    }
  }
}
