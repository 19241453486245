@import "../../../assets/scss/_customVariables.scss";

.payment-journey-modal {
  .payment-button {
    width: 180px;
  }

  .yapily-pis-journey-modal-container {
    .ant-card .ant-card-body {
      padding: 0px;
    }

    .yapily-bank-search {
      width: 100%;
    }

    .yapily-consent-subTitle {
      text-align: center;
    }

    .yapily-authentication-page {
      width: 100%;
      margin: 0px;
    }
  }

  .open-banking-payment-success,
  .open-banking-payment-scheduled {
    font-size: 90px;
    color: $brdcolSecondary;
  }

  .open-banking-payment-failure {
    font-size: 90px;
    color: #eb5757;
  }

  .ant-modal-footer {
    border: 0px;
    background: $lightgrey;

    .yapily-terms-footer {
      margin: 0px !important;
    }
  }
}
