@import "../../../assets/scss/customVariables";

.kloo-switch {
  margin-bottom: 0;
  &.ant-switch {
    height: 28px;
    min-width: 48px;
    background-color: $white;
    color: #fff;
    .ant-click-animating-node {
      display: none;
    }
    &:focus {
      box-shadow: unset !important;
      outline: unset !important;
    }
    border: 2px solid tint($txt-color, 50%);
    .ant-switch-handle {
      top: 3px;
    }
    .ant-switch-handle::before {
      background-color: tint($txt-color, 50%);
      margin-top: 1px;
    }
  }
  &.ant-switch-disabled {
    background-color: tint($txt-color, 95%);
    border: 2px solid tint($txt-color, 70%);
    .ant-switch-handle::before {
      background-color: tint($txt-color, 70%);
    }
  }
  &.ant-switch-checked {
    background: transparent;
    background-color: $primary-color;
    border: 2px solid $primary-color;
    .ant-switch-handle::before {
      background-color: $brdcolSecondary;
    }
    &.ant-switch-disabled {
      background-color: tint($primary-color, 40%);
      border-color: tint($primary-color, 40%);
      .ant-switch-handle::before {
        background-color: tint($brdcolSecondary, 50%);
      }
    }
  }
}
