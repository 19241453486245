@import "../../../../assets/scss/mixins";

.assign-workflow-inside-section {
  display: flex;
  .assign-workflow-left-section {
    width: 544px;
    @include customMinWidthBreakpoint(1024px) {
      width: auto;
    }
    .title-form {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      margin-top: 16px;
      color: #000000;
    }
    .assign-workflow-button {
      width: 100%;
    }
    .workflow-select {
      display: flex;
      flex-direction: column;
    }
  }
  .assign-workflow-right-section {
    margin: auto;
    .pdf-viewer {
      width: 336px;
      height: 472px;
      .inside-pdf-viewer {
        width: 100%;
        height: 100%;
      }
    }
    .invoice-images-total-count {
      position: relative;
      .total-count {
        position: absolute;
        top: 35px;
        right: 45%;
      }
    }
    .invoice-name-section {
      display: flex;
      margin: 16px 0;
      gap: 6px;
      max-width: 335px;
      .invoice-label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }
      .invoice-name {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
    .invoices-carousel {
      width: 336px;
      .react-multi-carousel-list {
        overflow-x: clip !important;
        overflow: unset;
        .react-multiple-carousel__arrow--left {
          bottom: calc(4% + -75px);
          background-color: transparent;
        }
        .react-multiple-carousel__arrow--left::before {
          color: #000000;
        }
        .react-multiple-carousel__arrow--right {
          bottom: calc(4% + -75px);
          background-color: transparent;
        }
        .react-multiple-carousel__arrow--right::before {
          color: #000000;
        }
      }
      .carousel-pdf-viewer {
        width: 107px;
        height: 150px;
        .react-multi-carousel-item {
          pointer-events: none;
        }
        .inside-carousel-pdf-viewer {
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
      }
    }
  }
}
