@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";
.assign-pr-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    @include lg {
      max-width: 1200px !important;
    }
  }
  .common_table {
    height: auto;
    padding: 0 16px 16px 16px;
    border-top: solid 1px $grey;

    thead {
      tr {
        th:last-child {
          padding: 0px;
          display: none;
        }
      }
    }
    tbody {
      tr {
        th:last-child {
          padding: 0px;
          display: none;
        }
      }
    }
  }
  .scrollable {
    overflow-y: auto;
    max-height: 90vh;
  }

  .pr-table {
    border: solid 1px $grey;
    border-radius: 4px;
  }
}
.max-width {
  max-width: 100px;
}
