@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.pdf-view-container {
  .common_table {
    tbody {
      tr {
        cursor: default;
      }
    }
    th {
      &:nth-child(3) {
        width: 25%;
      }
      &:nth-child(4) {
        width: 25%;
      }
    }
  }
  .supporting-attachments-container {
    border: solid 1px $grey;
    border-radius: 4px;
  }
  .scrollable-table {
    display: -webkit-box;
    overflow-x: auto;
  }
}
