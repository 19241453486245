@import "../../../../assets/scss/customVariables";
@import "../../../../assets/scss/mixins";

.supplier-account-view-container {
  border: 1px solid $grey;
  border-radius: 5px;
  overflow-y: auto;
  max-height: 350px;
  @include xxl {
    max-height: 60vh;
  }
  .supplier-account-view-wrapper {
    position: relative;
    .account-actions {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
