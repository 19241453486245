@import "../../../assets/scss/customVariables";

.ant-input-number-group-wrapper .ant-input-number-wrapper {
  .ant-input-number-group-addon {
    border: 2px solid tint($txt-color, 50%) !important;
    background: white;
  }
}

.common-input-number:not(.ant-input-number-group-wrapper),
.ant-input-number-group-wrapper .ant-input-number-affix-wrapper {
  border: 2px solid tint($txt-color, 50%) !important;
  padding: 7px 28px 7px 14px !important;
  border-radius: 4px !important;
  width: 100% !important;
  height: 40px !important;
  .ant-input-number-input {
    font-weight: 500 !important;
    color: $txt-color !important;
    padding: 0 !important;
    height: 22px !important;
    &::placeholder {
      color: tint($txt-color, 50%) !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: tint($txt-color, 50%) !important;
    }

    &::-ms-input-placeholder {
      color: tint($txt-color, 50%) !important;
    }
  }
  .ant-input-number-prefix,
  .ant-input-number-group-addon {
    font-weight: 500 !important;
    color: $txt-color !important;
  }
  .ant-input-number-in-form-item {
    padding: 0 !important;
    border: 0 !important;
  }
  &:hover,
  &:hover + .ant-input-number-group-addon {
    background-color: tint($txt-color, 95%) !important;
    .ant-input-number-in-form-item {
      background-color: tint($txt-color, 95%) !important;
    }
  }
  &.ant-input-number-focused {
    border: 2px solid tint($brdcolSecondary, 50%) !important;
    background-color: $white !important;
    box-shadow: none !important;
  }
  &.ant-input-number-affix-wrapper {
    .ant-input-number-in-form-item {
      height: 22px !important;
    }
  }
  &.ant-input-number-affix-wrapper-focused,
  &.ant-input-number-affix-wrapper-focused + .ant-input-number-group-addon {
    border: 2px solid tint($brdcolSecondary, 50%) !important;
    background-color: $white !important;
    box-shadow: none !important;
    .ant-input-number-in-form-item {
      padding: 0 !important;
      border: 0 !important;
      background-color: transparent !important;
    }
  }
  &.ant-input-number-status-error {
    border: 2px solid $destructive !important;
  }
  &.ant-input-number-affix-wrapper-status-error,
  &.ant-input-number-affix-wrapper-status-error
    + .ant-input-number-group-addon {
    border: 2px solid $destructive !important;
    .ant-input-number-in-form-item {
      padding: 0 !important;
      border: 0 !important;
      background: transparent !important;
    }
  }
  &.optional-field {
  }
  &.ant-input-number-disabled {
    background-color: tint($txt-color, 95%) !important;
    border: 2px solid tint($txt-color, 70%) !important;
    .ant-input-number-input {
      color: tint($txt-color, 70%) !important;
      -webkit-text-fill-color: tint($txt-color, 70%) !important;
      opacity: 1; /* required on iOS */
    }
  }
  &.ant-input-number-affix-wrapper-disabled,
  &.ant-input-number-affix-wrapper-disabled + .ant-input-number-group-addon {
    background-color: tint($txt-color, 95%) !important;
    border: 2px solid tint($txt-color, 70%) !important;
    .ant-input-number-prefix {
      color: tint($txt-color, 70%) !important;
    }
    .ant-input-number-in-form-item {
      border: 0 !important;
      padding: 0 !important;
    }
    .ant-input-number-input {
      &::placeholder {
        color: tint($txt-color, 70%) !important;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: tint($txt-color, 70%) !important;
      }

      &::-ms-input-placeholder {
        color: tint($txt-color, 70%) !important;
      }
    }
  }
  .ant-input-number-handler-wrap {
    .ant-input-number-handler-up:hover {
      .ant-input-number-handler-up-inner {
        color: $txt-color !important;
      }
    }
    .ant-input-number-handler-down:hover {
      .ant-input-number-handler-down-inner {
        color: $txt-color !important;
      }
    }
  }
}

.ant-input-number-group-wrapper .ant-input-number-wrapper {
  .ant-input-number-affix-wrapper {
    padding: 7px 7px 7px 14px !important;
    border-right: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

    + .ant-input-number-group-addon {
      padding: 0px;
      border-left: 0 !important;
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }
}
