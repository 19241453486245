.dynamically-created-input {
  padding: 5px 12px;
  cursor: pointer;
  color: #000;
  &:hover {
    background-color: #f5f5f5;
  }
}

.dynamic-list {
  .ant-select-dropdown {
    .ant-select-item-empty {
      padding: 0;
    }
  }
}
