@import "../../../../assets/scss/customVariables";

.common-phone-input {
  font-family: inherit !important;
  .flag-dropdown {
    border: 0 !important;
    border-right: 2px solid tint($txt-color, 50%) !important;
    width: 55px !important;
    background-color: transparent !important;
    transition: all 0.3s !important;
    .selected-flag {
      width: 55px !important;
      padding: 0 18px 0 18px !important;

      &:hover {
        background-color: transparent !important;
      }
      &:focus {
        background-color: transparent !important;
      }
    }
    &.open {
      .selected-flag {
        &.open {
          background-color: transparent !important;
        }
      }
    }
  }
  .form-control {
    width: 100% !important;
    border: 2px solid tint($txt-color, 50%) !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    height: 40px !important;
    color: $txt-color !important;
    padding: 7px 14px 7px 60px !important;
    transition: all 0.3s !important;
    font-size: 14px !important;
    &::placeholder {
      color: tint($txt-color, 50%) !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: tint($txt-color, 50%) !important;
    }

    &::-ms-input-placeholder {
      color: tint($txt-color, 50%) !important;
    }
    &:hover {
      background-color: tint($txt-color, 95%) !important;
      & + .flag-dropdown {
        .selected-flag {
          background-color: transparent !important;
        }
      }
    }
    &:focus {
      border: 2px solid tint($brdcolSecondary, 50%) !important;
      background-color: $white !important;
      box-shadow: none !important;
      & + .flag-dropdown {
        border-right: 2px solid tint($brdcolSecondary, 50%) !important;
      }
    }
    &:disabled {
      background-color: tint($txt-color, 95%) !important;
      border: 2px solid tint($txt-color, 70%) !important;
      &::placeholder {
        color: tint($txt-color, 70%) !important;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: tint($txt-color, 70%) !important;
      }

      &::-ms-input-placeholder {
        color: tint($txt-color, 70%) !important;
      }
      & + .flag-dropdown {
        border-right: 2px solid tint($txt-color, 70%) !important;
      }
    }
  }
  &.error {
    .form-control {
      border: 2px solid $destructive !important;
      &:focus {
        & + .flag-dropdown {
          border-right: 2px solid $destructive !important;
        }
      }
    }
    .flag-dropdown {
      border-right: 2px solid $destructive !important;
    }
  }
}

// .common-input {
//   padding: 9px 14px !important;
//   &:focus {
//     border: 2px solid tint($brdcolSecondary, 50%) !important;
//     background-color: $white !important;
//     box-shadow: none !important;
//   }
//   &.ant-input-status-error {
//     border: 2px solid $destructive !important;
//   }
//   &.optional-field {
//   }
// }
