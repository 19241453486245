@import "../../assets/scss/customVariables";
@import "../../assets/scss/mixins";

.with-logout {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
  p {
    margin-bottom: 0;
    text-align: right;
    font-weight: 600;
  }
}
@include customMaxWidthBreakpoint(480px) {
  .with-logout {
    flex-direction: column;
    align-items: flex-start;
    .show-counter {
      margin-top: 10px;
    }
  }
}
.login-form-wrapper {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form-wrapper-bg {
  width: 100%;
  position: absolute;
  height: calc(100% - 70px);
}

// .kloo-log-bg {
//   width: 100%;
//   position: absolute;
//   height: calc(100% - 70px);

//   &::before {
//     content: "";
//     width: 500px;
//     height: 500px;
//     position: absolute;
//     left: calc(50% - 190px);
//     top: 50%;
//     transform: translate(-50%, -50%);
//     background-image: url("../../assets/images/bg-logo.png");
//     background-repeat: no-repeat;
//     background-position: center center;
//     z-index: -1;
//   }
// }

.switch-profile-wrap {
  background-color: $white;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
  padding: 25px 25px 30px;
  @include customMaxWidthBreakpoint(576px) {
    margin: 0 15px;
  }
  h3 {
    font-size: 1.75rem;
    padding-top: 15px;
    padding-bottom: 15px;
    text-align: center;
    line-height: 1.5;
    font-weight: 600;
  }
  .switch-profile-list {
    list-style-type: none;
    gap: 20px;
    padding: 0;
    margin: 0;
    li {
      // margin-bottom: 20px;
    }
    .profile-avatar-wrap {
      cursor: pointer;
      padding: 10px;
      border: 1px solid transparent;
      border-radius: 8px;
      transition: 0.4s all;
      position: relative;
      display: flex;
      align-items: center;
      .profile-avatar {
        margin-right: 20px;
        .ant-avatar {
          margin: 0 !important;
        }
      }
      .profile-name {
        font-weight: 600;
        width: 100%;
        margin-bottom: 0;
        &.overflow-ellipsis {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      &:hover {
        border: 1px solid tint($brdcolSecondary, 50%);
        background-color: tint($brdcolSecondary, 90%);
      }
      .active-class {
        background: rgb(236, 253, 243);
        color: rgb(2, 122, 72);
      }
    }
    .profile-avatar-wrap-active {
      border: 1px solid tint($success, 50%);
      background-color: tint($success, 90%);
      cursor: not-allowed;
      &:hover {
        border: 1px solid tint($success, 50%);
        background-color: tint($success, 90%);
        cursor: not-allowed;
      }
    }
    .profile-avatar-active-status {
      position: absolute;
      top: -18px;
      left: 6px;
    }
  }
}
