.grni-offset-modal {
  .offset-amount-field-grni {
    .ant-form-item-row {
      justify-content: space-between;
      align-items: baseline;
    }
  }

  .ant-form-item-label > label {
    height: 100%;
    text-wrap: wrap;
  }
}
