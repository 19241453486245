@import "../../../assets/scss/customVariables";

.kloo-checkbox {
  .ant-checkbox-inner {
    border: 2px solid tint($txt-color, 50%);
    background-color: $white;
    width: 18px;
    height: 18px;
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $txt-color;
  }

  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: tint($txt-color, 95%);
    border-color: tint($txt-color, 70%) !important;
  }

  .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
    background-color: tint($brdcolSecondary, 50%);
    border: solid 2px tint($brdcolSecondary, 50%) !important;
    &::after {
      border-color: tint($primary-color, 45%);
    }
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner {
    border-color: $primary-color;
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $txt-color;
  }

  .ant-checkbox-checked .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $brdcolSecondary;
  }

  .ant-checkbox-checked {
    &::after {
      border-color: $brdcolSecondary;
    }
    .ant-checkbox-inner {
      background-color: $brdcolSecondary;
      border: solid 2px $brdcolSecondary;
      &::after {
        border-color: $primary-color;
      }
    }
  }
}
