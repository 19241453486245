.view-modal-container {
  height: auto;
  padding: 0 4rem;
  .workflow-content-section {
    overflow: auto;
    max-height: 65vh;
    .workflow-content {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 30px;
      .workflow-total-step {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        padding-right: 32px;
        align-items: center;
        display: flex;
      }
      .workflow-step-data {
        min-width: 130px;
        align-self: center;
        .workflow-approver-name-txt {
          font-size: 0.8rem;
          font-weight: 600;
          font-size: 0.8rem;
          line-height: 20px;
        }
        .workflow-approver-over-upto-txt {
          color: #858585;
          font-weight: 400;
          font-size: 0.8rem;
          line-height: 20px;
        }
        .workflow-approver-value-txt {
          color: #858585;
          font-weight: 400;
          font-size: 0.8rem;
          line-height: 20px;
        }
      }
      .workflow-approver-name-arrow {
        align-items: center;
        display: flex;
        margin-left: 1rem;
        margin-right: 1rem;
        white-space: nowrap;
        font-size: x-large;
      }
    }
  }
  .workflow-subtile-txt {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    padding: 20px 0;
  }
  .workflow-total-txt {
    padding-bottom: 8px;
  }
}
.edit-workflow-btn {
  padding: 20px 4rem;
  display: grid;
  grid-template-columns: 2fr 2fr;
  column-gap: 8px;
}
.workflow-content-section::-webkit-scrollbar {
  width: 8px;
}
.workflow-content-section::-webkit-scrollbar-track {
  background: rgb(228, 228, 228);
  border-radius: 20px;
}
.workflow-content-section::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 20px;
}
