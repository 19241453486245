@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.po_management {
  .po-header-wrapper {
    Button {
      margin-left: 0px;
      transition: none;
      @include xl {
        margin-left: 30px;
      }
    }
  }
  .historySearch .ant-input-affix-wrapper {
    height: 44px;
    border: 1px solid #000;
    border-radius: 8px;
  }
}

.purchase-requests {
  .ant-card-body {
    .ant-row {
      align-items: center;
    }
  }
}
