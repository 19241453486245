@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.download-invoice-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    @include sm {
      width: 464px !important;
    }
  }
  .document-viewer-height {
    height: calc(100% - 56px) !important;
  }
  .invoice-cnt {
    border: 1px solid $brdcolSecondary;
  }

  iframe {
    height: calc(100vh - 230px);
    border: 0;
  }

  .download-invoice-drawer-footer {
    background-color: $lightgrey;
    position: absolute;
    z-index: 100;
    left: 0;
    bottom: 0;
  }
}
