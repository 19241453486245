@import "../../../assets/scss/customVariables";

.post-comment-container {
  ::-webkit-scrollbar {
    display: none;
  }
  .textarea-container {
    position: relative;
  }
  .ant-input {
    &:placeholder-shown {
      padding-top: 9px !important;
    }
    &:focus {
      padding-top: 9px !important;
      line-height: 1.4em;
    }
  }
  .ant-form-item-explain-error {
    margin-bottom: 0px !important;
  }
  .comment-input {
    position: relative;
    resize: none;
    padding-right: 8% !important;
    overflow-y: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: initial;
    min-height: 40px !important;
    line-height: 1.45em;
  }

  .textarea-container .counter {
    position: absolute;
    // bottom: 9px;
    right: 15px;
    color: $darkGrey;
    white-space: nowrap;
  }

  /* Vibration animation */
  @keyframes horizontal-shaking {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
  .horizontal-shake {
    animation: horizontal-shaking 0.2s 0.5s;
  }

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .with-error-style {
    transform: translateY(-45px) !important;
  }

  .without-error-style {
    transform: translateY(-30px) !important;
  }

  .post-comment-button {
    align-self: flex-end;
  }
}
