@import "../scss/customVariables";
@import "_mixins";

.common_table {
  //   height: calc(100vh - 372px);
  //   overflow: auto;

  .table_no_record {
    position: sticky;
    left: 50%;
    transform: translateY(50%);
    background-color: #ffffff !important;
  }

  position: relative;
  margin-bottom: 16px;
  background-color: #fff;
  padding: 0px 16px 0px 16px;

  .dataTable {
    width: 100%;
    text-align: left;
    margin-bottom: 40px;
    table-layout: fixed;

    .status-tag {
      width: fit-content;
      white-space: normal;
    }

    .selected-row {
      background-color: $brdcolSecondary;
      span.ant-typography {
        color: rgba($txt-color, 1);
      }
    }

    .amount {
      text-align: right;
    }

    thead {
      background-color: #fff;
      tr {
        th {
          padding: 12px;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          color: $txt-color;
          background-color: $white;
        }
        th.sticky-column {
          position: sticky;
          left: 0;
          z-index: 1;
          min-width: 200px;
        }
      }
    }

    tbody {
      width: auto;
      top: 0;
      z-index: 99;
      background: #fff;
      // min-height: 364px;

      tr {
        cursor: pointer;
        td {
          padding: 10px 12px;
          background-color: rgba($primary-color, 0.05);
          // border-radius: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $txt-color;
          height: 48px;
          span {
            &.ant-tag:first-letter {
              text-transform: uppercase;
            }
          }

          // &::first-letter {
          //   text-transform: uppercase;
          // }

          // span {
          //   &::first-letter {
          //     text-transform: uppercase;
          //   }
          // }

          &:first-child {
            border-radius: 4px 0 0 4px;
          }

          &:last-child {
            border-radius: 0 4px 4px 0;
          }
        }

        &:nth-child(2n) {
          td {
            background-color: rgba($white, 0);

            // span {
            //   &::first-letter {
            //     text-transform: uppercase;
            //   }
            // }
          }
        }

        &.selected-row th {
          background-color: $brdcolSecondary;
          .ant-checkbox-inner {
            border-color: $primary-color;
          }
        }

        .whiteSpace {
          background-color: #000;
          height: 24px;
          width: 100%;
        }

        &:hover {
          td {
            background-color: rgba($brdcolSecondary, 0.1);
          }
        }
      }
    }

    tfoot {
      tr {
        display: grid;
        td {
          padding: 10px 12px;
          background-color: rgba($primary-color, 0.05);
          // border-radius: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $txt-color;
          height: 48px;
          span {
            &.ant-tag:first-letter {
              text-transform: uppercase;
            }
          }
          &:first-child {
            border-radius: 4px 0 0 4px;
          }

          &:last-child {
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }
  }
  .scroll-table-body {
    tbody {
      width: auto;
      top: 0;
      z-index: 99;
      background: #fff;
    }
  }

  a.kloo-action-link,
  a.kloo-link {
    color: $txt-link;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.ant-tag:first-letter {
  text-transform: uppercase;
}

.card_table_div {
  background-color: #fff;
}
.card_table_div {
  .common_table {
    //   height: calc(100vh - 372px);
    //   overflow: auto;

    .table_no_record {
      position: sticky;
      left: 50%;
      transform: translateY(50%);
      background-color: #ffffff !important;
    }

    position: relative;
    margin-bottom: 16px;
    background-color: #fff;
    padding: 0px 0px 0px 0px;

    @include customMinWidthBreakpoint(1024px) {
      padding: 0px 16px 0px 16px;
    }

    .dataTable {
      width: 100%;
      text-align: left;
      margin-bottom: 40px;

      .status-tag {
        width: fit-content;
        white-space: normal;
      }

      .amount {
        text-align: right;
      }

      thead {
        position: sticky;
        z-index: 99;
        top: 0;
        background-color: #fff;

        tr {
          th {
            padding: 15px;
            font-weight: 600;
            font-size: 14px;
            line-height: 15px;
            color: #000000;
          }
        }
      }
      tbody {
        width: auto;
        top: 0;
        z-index: 99;
        background: #fff;
      }

      tbody {
        // min-height: 364px;

        tr {
          cursor: pointer;

          td {
            padding: 10px 15px;
            background-color: rgba(17, 39, 255, 0.05);
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #000000;
            height: 48px;

            // &::first-letter {
            //   text-transform: uppercase;
            // }

            span {
              &::first-letter {
                text-transform: uppercase;
              }
            }

            &:first-child {
              border-radius: 4px 0 0 4px;
            }

            &:last-child {
              border-radius: 0 4px 4px 0;
            }
          }

          &:nth-child(2n) {
            td {
              background-color: #fff;

              // span {
              //   &::first-letter {
              //     text-transform: uppercase;
              //   }
              // }
            }
          }

          .whiteSpace {
            background-color: #000;
            height: 24px;
            width: 100%;
          }
        }
      }
    }
  }
}

.common_table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(228, 228, 228);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 20px;
  }
}
.hide-scroll-bar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.common_table {
  position: relative;

  .header-table {
    position: sticky;
    width: -webkit-fill-available;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 0 !important;

    thead {
      width: auto;
      top: 0;
      z-index: 99;
      background: #fff;
    }
  }
  .infinite-scroll-component__outerdiv {
    .infinite-scroll-component {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background: rgb(228, 228, 228);
        border-radius: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #bdbdbd;
        border-radius: 20px;
      }
    }
  }
}

.card_table_div {
  .common_table {
    position: relative;

    .header-table {
      position: sticky;
      width: -webkit-fill-available;
      top: 0;
      margin-bottom: 0 !important;

      thead {
        width: auto;
        top: 0;
        z-index: 99;
        background: #fff;
      }
    }
    .infinite-scroll-component__outerdiv {
      .infinite-scroll-component {
        overflow: unset !important;
        @include customMinWidthBreakpoint(1024px) {
          // overflow: auto !important;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

.audit-table {
  .dataTable {
    table-layout: fixed;
    min-width: 900px;
    @include customMinWidthBreakpoint(1440px) {
      min-width: unset;
    }
  }
  th {
    &:first-child {
      width: 12%;
    }

    &:nth-child(2) {
      width: 10%;
    }
    &:nth-child(3) {
      width: 18%;
    }
    &:nth-child(4) {
      width: 30%;
    }
    &:nth-child(5) {
      width: 30%;
    }
  }
}

.kloo-select-all-column-header {
  .kloo-select-all-dropdown {
    cursor: pointer;
    &.active {
      color: $brdcolSecondary;
    }

    &.disabled {
      color: tint($txt-color, 40%);
      cursor: not-allowed;
    }
  }
}

.kloo-select-all-column-header-popover {
  &.ant-popover-placement-bottomLeft {
    padding: 0px;
  }

  .ant-popover-inner-content {
    padding: 8px 0px;
  }

  .kid-item-body {
    cursor: pointer;
    &:hover {
      background-color: #f5f5f5;
      border-radius: 4px 0 0 4px;
    }
  }
}

.table-component-arrow {
  font-size: 26px !important;
}

.transform-arrow {
  transform: rotate(270deg);
}

.expanded-div-tr {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000e1c;
}

.tooltip-container {
  &::after {
    content: "";
    display: block;
  }
}

.expanded-div {
  margin: 0px 0px 20px 40px;
}

.expanded-div-td {
  border-top: 1px solid $grey !important;
}

.grey-background {
  background-color: rgba($primary-color, 0.05) !important;
}

.white-background {
  background-color: $white !important;
}

.tooltip-container {
  &::after {
    content: "";
    display: block;
  }
}
