.not-found {
  height: 100vh;
  width: 100vw;
  .not-found-inner-wrap {
    max-width: 600px;
    width: 100%;
  }
}

.inactive-content {
  .not-found {
    height: 100%;
    width: 100%;
  }
}
