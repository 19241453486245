.password-form-item {
  .ant-form-item-explain-error:not(:first-child) {
    margin-bottom: 0;
  }
  &.remove-margin-bottom {
    margin-bottom: 15px;
  }
  .kloo-material-icons {
    &.ant-input-password-icon {
      &:hover {
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
.password-rule-container {
  margin-bottom: 24px;
  background: #fff7f5;
  border: 1px solid #ee6c4d;
  border-radius: 4px;
  padding: 10px;
  .checklist-title {
    font-weight: 500;
  }
  .icon-message-container {
    padding-left: 23px;
    position: relative;
    .checklist-icon {
      position: absolute;
      left: 0;
      top: 2px;
      font-size: 12px;
      &.material-icons {
        font-size: 18px;
      }
    }
    .password-valid {
      color: #ee6c4d;
    }
    .password-error {
      color: #9ca1ad;
    }
    .password-text {
      color: #232325;
      font-size: 14px;
    }
  }
}
