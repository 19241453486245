@import "../../../assets/scss/customVariables";

.custom-label {
  font-size: 14px !important;
  font-weight: 600 !important;
  display: inline-block !important;
  color: inherit !important;
  .required-asterisk {
    color: $destructive;
  }
}
.tooltip-inside-label {
  font-weight: normal !important;
}

.required-asterisk-container {
  position: absolute;
}
