@import "../../assets/scss/mixins";
@import "../../assets/scss/customVariables";

.total-invoices-section {
  background-color: #fff;
}
.payment-run-table {
  .dataTable {
    table-layout: fixed;
    @include customMinWidthBreakpoint(1632px) {
      min-width: unset;
    }
  }
  .suppliers-name {
    font-weight: 500;
  }
}

.payment_runs {
  // .kloo-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  //   margin: 0 0 0 1.5rem;
  // }
  .card_filter {
    padding: 15px;

    @include md {
      padding: 5px 24px 5px 24px;
    }
  }
}

.common_table.payment-run-table {
  tbody {
    tr {
      cursor: default;
    }
  }
}

.display-content {
  display: contents;
}

.align-center {
  align-items: center;
}

.filter-container {
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sorting-arrow {
  cursor: pointer;
  font-size: 16px !important;
  width: 0px;
  height: 3px;
}
.active-sorting-arrow {
  cursor: pointer;
  font-size: 16px;
  width: 0px;
  height: 3px;
  color: $brdcolSecondary;
  // color: shade($brdcolSecondary, 10%);
}
.disable-sorting-arrow {
  cursor: pointer;
  font-size: 16px;
  width: 0px;
  height: 3px;
  color: $grey;
}

.selected-text {
  text-wrap: nowrap;
}

.icons {
  position: unset;
  align-self: center;
  margin-top: -10px;
}

.payment-runs {
  .ant-card-body .ant-row {
    align-items: center;
  }
}

.invoices-list {
  .ant-form-item,
  .ant-form-item-explain-error {
    margin-bottom: 0 !important;
  }
}

.email-list {
  .ant-form-item,
  .ant-form-item-explain-error {
    margin-bottom: 0 !important;
  }
  .ant-checkbox + span {
    padding-left: 16px;
  }
}
