.modal-header {
  .modal-title-close-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
  .material-icons {
    cursor: pointer;
  }
}
