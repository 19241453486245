@import "../../assets/scss/customVariables";
@import "../../assets/scss/mixins";
@import "../../assets/scss/utility";

.kloo-sidebar {
  .ant-menu-item {
    margin-bottom: 0 !important;
    height: 47px !important;
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item {
    display: flex !important;
  }

  .ant-menu-vertical.ant-menu-root .ant-menu-item {
    @include md {
      display: block !important;
    }
  }

  .ant-layout-sider {
    width: 262px !important;
    flex: 0 0 262px !important;
    max-width: 262px !important;
    min-width: 262px !important;
  }

  & {
    .ant-layout-sider-children {
      // min-height: 100vh;
      display: flex;
      flex-direction: column;
      background-color: #fff;

      .menu-wrapper {
        height: calc(100vh - 56px);
        // margin-top: 2.3rem;
        overflow-y: auto;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
      }

      .menu-wrapper::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
      }
    }

    .open-close-sidenav {
      //  padding-right: 10px;
      //  padding-bottom: 10px;
      position: absolute;
      top: 0;
      right: 0;
      background: #fff;
      padding: 4px 5px 0;
      color: $txt-color;
      border-radius: 0 0 0 5px;
      cursor: pointer;

      @include md {
        position: relative;
        text-align: center;
        margin-top: 110px;
        align-self: center;
        cursor: pointer;
        color: tint($primary-color, 20%);
      }
    }
  }

  &.ant-layout-sider-collapsed {
    .open-close-sidenav {
      padding-right: 0;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  .ant-layout-sider.ant-layout-sider-dark {
    background-color: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    // z-index: 1000;

    &::before {
      content: "";
      position: fixed;
      z-index: -1;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.45);
    }

    &.ant-layout-sider-collapsed {
      &::before {
        content: unset;
      }
    }

    @include md {
      position: static;

      &::before {
        content: unset;
      }
    }
  }

  .ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed {
    transform: translateX(-100%);

    @include md {
      transform: translateX(0);
      width: 66px !important;
      flex: 0 0 66px !important;
      max-width: 66px !important;
      min-width: 66px !important;
    }

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .ant-menu-inline {
    width: 100%;
    padding-bottom: 100px;

    @include md {
      padding-bottom: 0;
    }
  }

  .ant-menu-inline .ant-menu-item {
    &::after {
      border: none;
    }
  }

  .ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical-left .ant-menu-item,
  .ant-menu-vertical-right .ant-menu-item,
  .ant-menu-inline .ant-menu-item,
  .ant-menu-vertical .ant-menu-submenu-title,
  .ant-menu-vertical-left .ant-menu-submenu-title,
  .ant-menu-vertical-right .ant-menu-submenu-title,
  .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 16px !important;
  }

  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 3px;
  }

  .ant-menu-submenu:hover
    > .ant-menu-submenu-title
    > .ant-menu-submenu-expand-icon,
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: $txt-color;
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      &::before {
        content: "";
        width: 6px;
        height: 100%;
        background-color: $txt-color !important;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0 4px 4px 0;
      }
      span {
        color: $txt-color !important;
      }
    }
  }

  .ant-layout-sider,
  .ant-menu-item {
    background: $white;
    &:hover {
      background-color: tint($brdcolSecondary, 90%) !important;
    }

    li {
      .sidenav-icon-container {
        line-height: 1;
      }

      .ant-menu-item-icon {
        color: tint($primary-color, 40%);
        display: flex;
        align-items: center;
        height: 100%;
      }

      &:hover {
        // background-color: rgba(17, 39, 255, 0.05);
        .ant-menu-item-icon {
          color: $txt-color;
        }

        // .ant-menu-title-content {
        //   color: #fff !important;
        // }
      }
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active,
    .ant-menu-submenu-title:active {
      background: $white;
    }

    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-arrow {
      color: $txt-color;
    }
  }
  .ant-menu-submenu {
    .ant-menu-item .ant-menu-item-icon + span,
    .ant-menu-submenu-title .ant-menu-item-icon + span,
    .ant-menu-item .anticon + span,
    .ant-menu-submenu-title .anticon + span {
      margin-left: 22px;
      color: tint($primary-color, 20%);
      font-size: 14px;
      line-height: 24px;
    }
    .ant-menu-vertical > .ant-menu-item,
    .ant-menu-vertical-left > .ant-menu-item,
    .ant-menu-vertical-right > .ant-menu-item,
    .ant-menu-inline > .ant-menu-item,
    .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
    .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
      height: 47px !important;
    }

    .ant-menu-sub.ant-menu-inline > .ant-menu-item,
    .ant-menu-sub.ant-menu-inline
      > .ant-menu-submenu
      > .ant-menu-submenu-title {
      margin: 0;
      padding-left: 16px !important;
    }

    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow {
      color: tint($primary-color, 20%);
    }

    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-expand-icon,
    .ant-menu-submenu:hover
      > .ant-menu-submenu-title
      > .ant-menu-submenu-arrow {
      color: $txt-color;
    }
  }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-item,
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-item-group
    > .ant-menu-item-group-list
    > .ant-menu-submenu
    > .ant-menu-submenu-title,
  .ant-menu.ant-menu-inline-collapsed
    > .ant-menu-submenu
    > .ant-menu-submenu-title {
    padding: 0 calc(50% - 24px / 2);
  }
  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
    font-style: normal;
    // font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: tint($primary-color, 20%);
    font-weight: 400;

    a {
      color: tint($primary-color, 20%);
      &:hover {
        color: $txt-color;
        text-decoration: none;
      }
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: tint($brdcolSecondary, 90%) !important;
    position: relative;
    &::before {
      content: "";
      width: 6px;
      height: 100%;
      background-color: $primary-color !important;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 0 4px 4px 0;
    }
  }

  .ant-menu-submenu {
    .ant-menu-sub {
      .ant-menu-item {
        height: 34px !important;
        span {
          a {
            font-size: 12px !important;
          }
        }
      }
      .ant-menu-item-selected {
        &::before {
          content: unset;
        }
      }
    }
  }

  .ant-menu-submenu-expand-icon {
    transform: rotate(-90deg) translateY(0);
    font-size: 20px;
    height: 20px;
    width: 20px;
    top: 25%;
  }
  .ant-menu-submenu-active {
    background-color: tint($brdcolSecondary, 90%) !important;
    // .ant-menu-submenu-title {
    //   span {
    //     font-weight: 600 !important;
    //   }
    // }
    .ant-menu-item {
      background-color: tint($brdcolSecondary, 90%) !important;
    }
  }
  .ant-menu-submenu-selected {
    background-color: tint($brdcolSecondary, 90%) !important;
    .ant-menu-submenu-title {
      span {
        font-weight: 600 !important;
      }
    }
  }
  .ant-menu-submenu-open {
    background-color: tint($brdcolSecondary, 90%) !important;
    .ant-menu-submenu-title {
      span {
        font-weight: 600 !important;
      }
    }
    .ant-menu-item {
      background-color: tint($brdcolSecondary, 90%) !important;
    }
    .ant-menu-submenu-expand-icon {
      transform: rotate(0deg) translateY(0);
    }
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected span {
    color: $txt-color;
    // display: flex;
    align-items: center;

    // height: 100%;
    a {
      color: #ffffff;
      // display: flex;
      align-items: center;
      // height: 100%;
    }
  }

  .ant-menu:not(.ant-menu-horizontal)
    .ant-menu-item-selected
    .ant-menu-title-content {
    color: #ffffff;
    // display: flex;
    align-items: center;

    // height: 100%;
    a {
      color: $txt-color;
      font-weight: 600;
      // display: flex;
      align-items: center;
      // height: 100%;
    }
  }

  // .anticon {
  //   height: 24px;
  //   width: 24px;
  // }

  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .anticon {
    position: absolute;
    width: 100px;
    height: 50px;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -13px;
  }
}

.site-layout-background {
  .logoMain {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    cursor: pointer;
  }

  .logoCombine {
    transition: all 0.2s;
    display: flex;
    flex-direction: row;

    .img-display {
      transition: all 0.2s;

      @include md {
        display: none;
      }
    }
  }

  .logoCombine-2 {
    transition: all 0.2s;
    display: flex;
    flex-direction: row;
    margin-left: 0;

    .img-display {
      transition: all 0.2s;

      @include md {
        display: none;
      }
    }
  }

  .logoOnly {
    padding: 17px 7px 17px 5px;
  }

  .ant-layout-sider-collapsed {
    .logoOnly {
      padding: 17px 11px 17px 20px;
    }
  }
}
