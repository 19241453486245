.line {
  width: 2px;
  height: 20px;
  background-color: black;
}

.list-container {
  max-height: 347px;
  overflow-y: auto;
  margin-bottom: 10px;
}
