@import "../../../assets/scss/customVariables";
@import "../../../assets/scss/mixins";
/**********Tabs css*****************/
// TODO: !important to be removed when Tab's SCSS is removed from Layout.scss file
.kloo-tab {
  .ant-tabs-nav {
    background: $white;
    margin: 0;
    .ant-tabs-nav-wrap {
      font-style: normal;
      // font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #a6a6a6;
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          font-style: normal;
          font-size: 12px;
          line-height: 22px;
          margin: 0 0 0 0;
          padding: 12px 0px 8px;
          border-bottom: 2px solid transparent;

          @include xl {
            font-size: 16px;
          }
          .ant-tabs-tab-btn {
            color: tint($txt-color, 70%);
          }
          &.ant-tabs-tab-active {
            font-weight: 600;
            border-bottom: 2px solid $primary-color;
            .ant-tabs-tab-btn {
              color: $txt-color !important;
              position: relative;
            }
          }
          & + .ant-tabs-tab {
            margin: 0 0 0 1.2rem;
            @include customMinWidthBreakpoint(1024px) {
              margin: 0 0 0 24px;
            }
          }
        }
        .ant-tabs-ink-bar {
          background: $brdcolSecondary;
          height: 0;
          margin-top: 12px;
        }
      }
    }
  }
  .ant-tabs-content-holder {
    .ant-tabs-content {
      position: unset;
    }
  }
}
