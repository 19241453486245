.input-range-container {
  width: 250px;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .range-input {
    width: 100px;
  }
  .commonInput {
    padding: 7px 7px !important;
    border: 1px solid #9ca1ad;
    .ant-input-number-input {
      padding: 0px;
    }
  }

  .divider {
    width: 20px;
    border: 1px solid #9ca1ad;
  }
}
