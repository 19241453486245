@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.attachment-cnt {
  border: solid 1px $grey;
  border-radius: 4px 4px 0 0;
}

.upload-po-section {
  border: solid 1px $grey;
  border-top: 0;
  border-radius: 0 0 4px 4px;
  .attachment-list-section {
    border-bottom: solid 1px $grey;
  }
}

.attachment-modal-height {
  max-height: 600px;
  overflow-y: auto;
  .attachment-modal-pdf-section {
    height: 600px !important;
  }
}
