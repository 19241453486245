@import "../../../../assets/scss/customVariables";
@import "../../../../assets/scss/mixins";

.supplier-management-drawer {
  .ant-drawer-content-wrapper {
    @include md {
      width: 36rem !important;
    }
  }
}
