@import "../../../../assets/scss/customVariables";

.invoice-matching-modal {
  overflow-y: hidden !important;
  .border {
    border: 1px solid $grey;
  }

  .divider {
    border-bottom: 1px solid $grey;
    width: 100%;
    height: 0px;
  }

  .hr {
    border-top: 1px solid $grey;
    margin: 16px 2px;
    width: 100%;
  }

  .flex-1 {
    flex: 1;
    align-items: initial;
  }

  .allocated-amount-text-field {
    margin-bottom: 0px !important;
    .ant-form-item {
      margin-bottom: 0px !important;
    }
    .ant-form-item-explain-error {
      white-space: normal;
    }
  }

  .action-btn-parent {
    max-height: 44px;
  }
}
