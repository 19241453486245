@import "../../../assets/scss/customVariables";
.dob-modal {
  .DOB-modal-form {
    .ant-form-item {
      width: 100%;
    }
  }

  svg {
    display: block;
    height: 70px;
    width: 70px;
    color: $brdcolSecondary;
  }

  .circle {
    stroke-dasharray: 76;
    stroke-dashoffset: 76;
    animation: draw 0.5s forwards;
  }

  .tick {
    stroke-dasharray: 18;
    stroke-dashoffset: 18;
    animation: draw 0.5s forwards 0.5s;
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }
}
