@import "../../../assets/scss/customVariables";

.common-date-picker {
  border: 2px solid tint($txt-color, 50%) !important;
  padding: 7px 14px !important;
  border-radius: 4px !important;
  width: 100% !important;
  transition: all 0.3s !important;
  .ant-picker-input {
    input {
      &::placeholder {
        color: tint($txt-color, 50%) !important;
        opacity: 1;
        font-weight: 500 !important;
      }
      &:-ms-input-placeholder {
        color: tint($txt-color, 50%) !important;
        font-weight: 500 !important;
      }

      &::-ms-input-placeholder {
        color: tint($txt-color, 50%) !important;
        font-weight: 500 !important;
      }
      font-weight: 500 !important;
      color: $txt-color !important;
    }
  }
  .ant-picker-suffix {
    i {
      color: $txt-color !important;
      font-size: 22px !important;
    }
  }

  &:hover {
    background-color: tint($txt-color, 95%) !important;
    .ant-picker-clear {
      background-color: tint($txt-color, 95%) !important;
    }
  }
  &.ant-picker-focused {
    border: 2px solid tint($brdcolSecondary, 50%) !important;
    background-color: $white !important;
    .ant-picker-clear {
      background-color: $white !important;
    }
  }
  &.ant-picker-status-error {
    border: 2px solid $destructive !important;
  }
  &.ant-input-status-error {
    border: 2px solid $destructive !important;
  }
  &.optional-field {
  }
  &:disabled {
    background-color: tint($txt-color, 95%) !important;
    border: 2px solid tint($txt-color, 70%) !important;
    &::placeholder {
      color: tint($txt-color, 70%) !important;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      color: tint($txt-color, 70%) !important;
    }

    &::-ms-input-placeholder {
      color: tint($txt-color, 70%) !important;
    }

    .ant-picker-clear {
      background-color: tint($txt-color, 95%) !important;
    }
  }
  &.ant-picker-disabled {
    border: 2px solid tint($txt-color, 70%) !important;
    background-color: tint($txt-color, 95%) !important;
    input {
      color: tint($txt-color, 70%) !important;
    }
    .ant-picker-suffix {
      color: tint($txt-color, 70%) !important;
    }
  }
}

.kloo-multi-level-filter-popup {
  .ant-picker-focused {
    box-shadow: 0 0 0 2px tint($primary-color, 95%);
  }
  .ant-picker-input > input:hover {
    border-color: $primary-color;
  }
  .ant-picker-range .ant-picker-active-bar {
    background: $brdcolSecondary;
  }
}

.kloo-range-picker {
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: $brdcolSecondary;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
      .ant-picker-cell-range-start
    ):not(.ant-picker-cell-range-end):not(
      .ant-picker-cell-range-hover-start
    ):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: $brdcolSecondary;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: $txt-color;
    background: $brdcolSecondary;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: tint($brdcolSecondary, 90%);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before {
    background: tint($brdcolSecondary, 90%);
  }

  .ant-picker-cell-in-view {
    color: $primary-color;
  }
  .ant-picker-cell-range-hovdisableder {
    background: tint($primary-color, 95%);
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(
      .ant-picker-cell-in-range
    ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(
      .ant-picker-cell-in-range
    ):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(
      .ant-picker-cell-in-range
    )::after {
    border-top: 1px dashed tint($txt-color, 90%) !important;
    border-bottom: 1px dashed tint($txt-color, 90%) !important;
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
  tr
    > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(
      .ant-picker-cell-range-hover-edge-end-near-range
    )::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
    border-right: 1px dashed tint($txt-color, 90%) !important;
  }

  tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
  tr
    > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
  .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(
      .ant-picker-cell-range-hover-edge-start-near-range
    )::after,
  .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
    border-left: 1px dashed tint($txt-color, 90%) !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ).ant-picker-cell-range-hover-start::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ).ant-picker-cell-range-hover-end::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
    background: tint($txt-color, 90%) !important;
  }

  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: tint($txt-color, 90%) !important;
  }

  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
    .ant-picker-cell-inner::after,
  .ant-picker-date-panel
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
    .ant-picker-cell-inner::after {
    background: tint($brdcolSecondary, 90%);
  }
}

.kloo-date-picker {
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $brdcolSecondary;
    color: $black;
  }
  .ant-picker-cell-disabled::before {
    background: tint($primary-color, 95%);
  }

  .ant-picker-cell-in-view {
    color: $primary-color;
  }
  .ant-picker-cell-range-hover {
    background: tint($primary-color, 95%);
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 1px solid $brdcolSecondary;
  }
  .ant-picker-today-btn {
    color: $brdcolSecondary;
    &:hover {
      color: $txt-color;
      text-decoration: none;
    }
  }
}
