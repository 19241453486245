@import "_customVariables";
@import "_mixins";
@import "_utility";

::-moz-selection {
  /* Code for Firefox */
  color: $txt-color !important;
  background: tint($cyanshade, 70%) !important;
}

::selection {
  color: $txt-color !important;
  background: tint($cyanshade, 70%) !important;
}

.ant-layout {
  height: auto;

  .ant-layout-header {
    padding: 0 12px;
  }

  .site-layout {
    .site-layout-background {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: $primary-color;
      height: 56px;
    }
  }

  .ant-layout .headerMain {
    justify-content: space-between !important;
    display: inline-flex;
    width: 100%;
    align-items: center;

    .burgerMain {
      cursor: pointer;
      color: #fff;
      line-height: 1;

      span {
        font-size: 30px;

        @include md {
          display: none;
        }
      }
    }
  }

  .headerRight {
    display: flex;
    align-items: center;

    // .dropDownMenu {
    //   margin-right: 16px;
    // }

    .userIconDiv {
      cursor: pointer;

      // margin-right: 16px;
      // .ant-dropdown {
      //   .ant-dropdown-menu {
      //     .ant-dropdown-menu-item {
      //       line-height: 32px !important;
      //       width: 10rem !important;
      //     }
      //   }
      // }
      .user-profile-dropdown {
        padding: 10px;
        line-height: 1;
        color: #fff;

        &:hover {
          background-color: #fff;
          color: $txt-color;
        }
      }
    }
  }

  .iconBellMain {
    padding: 10px;
    position: relative;
    cursor: pointer;
    line-height: 1;
    color: #fff;

    &:hover {
      background-color: #fff;
      color: #1127ff;
    }
  }

  .iconBell {
    fill: #1127ff !important;
    height: 20px;
    width: 20px;
  }

  .ant-layout .iconBellMain span {
    background: #ff6711;
    color: #fff;
    position: absolute;
    padding: 2px;
    margin: 0;
    height: 16px;
    text-align: center;
    top: 2px;
    right: 3px;
    border-radius: 50%;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 14px;
    width: 16px;
  }

  .dropDownMenu {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $brdcolSecondary;
  }

  .ant-space.ant-space-horizontal.ant-space-align-center {
    padding: 0 20px;
    height: 54px;

    .material-icons {
      color: #fff;
    }

    &:hover {
      background-color: transparent;

      @include sm {
        background-color: #fff;
      }

      .material-icons {
        color: #ffffff;

        @include sm {
          color: $txt-color;
        }
      }

      .dropTopText {
        color: $txt-color;
      }

      .dropBottomText {
        color: $txt-color;
      }
    }
  }

  .ant-dropdown-menu {
    margin-top: 35px !important;
  }

  .dropText {
    display: none;

    @include sm {
      display: block;
    }
  }

  .dropText span {
    display: block;
  }

  .dropTopText {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
  }

  .dropBottomText {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
  }
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active,
.ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-light .ant-menu-submenu-active,
.ant-menu-light .ant-menu-submenu-title:hover {
  color: $txt-color !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected span {
  color: tint($primary-color, 20%);
  // display: flex;
  align-items: center;

  // height: 100%;
  a {
    color: tint($primary-color, 20%);
    font-weight: 600;
    // display: flex;
    align-items: center;
    // height: 100%;
  }

  &:hover {
    color: $txt-color;
  }
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: $primary-color !important;
}
.ant-dropdown-menu-item:hover {
  background-color: tint($brdcolSecondary, 90%) !important;
  color: $txt-color !important;
}

.ant-dropdown-menu-item:hover path {
  fill: #fff !important;
}

.ant-space.ant-space-horizontal.ant-space-align-center {
  gap: 57px !important;
}

/* Right Sider Css*/
.bodyCenter {
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 10px 10px 0;
  height: calc(100vh - 83px);

  @include lg {
    padding: 16px 16px 0;
  }

  .card_header_info {
    .ant-card-bordered {
      border: unset;
    }

    // .users_header_body {
    //   height: 110px !important;s
    // }
    .card_header_layout {
      width: auto;

      // margin: 20px 16px 0 16px;
      .card_header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @include lg {
          flex-direction: row;
        }

        .card_header_left {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;

          .card_name {
            display: flex;
            flex-direction: row;
            align-self: center;

            h1 {
              text-align: left;
            }

            img {
              align-self: center;
              margin-left: 10px;
              width: 12px;
              height: 8px;
            }
          }

          .add_new_card {
            align-self: center;

            Button {
              // display: flex;
              // flex-direction: row;
              // justify-content: space-between;
              // background: #ee6c4d;
              // border-radius: 8px;
              // border: none;
              // align-items: center;
              // padding: 10px;
              // margin-left: 0px;
              // min-height: 44px;
              // margin-bottom: 8px;

              @include lg {
                // padding: 9px 28px;
                margin-bottom: 0px;
                margin-left: 30px;
              }

              &:hover,
              &:focus {
                border-color: #ee6c4d;
              }

              span {
                color: #fff;
              }

              p {
                margin-left: 10px;
                margin-bottom: 0;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                color: #ffffff;
                white-space: nowrap;

                @include customMinMaxWidthBreakpoint(220px, 480px) {
                  text-align: left;
                  white-space: pre-line;
                }
              }
            }
          }
        }

        .card_header_right {
          position: relative;
          height: auto;
          width: auto;
          margin-top: 10px;
          @include customMinWidthBreakpoint(1024px) {
            // height: 44px;
            margin-top: auto;
          }

          // width: 336px;
          .card_search::placeholder {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            opacity: 0.3;
          }

          span.ant-input-affix-wrapper {
            height: 44px;
            border: 1px solid #000;
            border-radius: 8px;
          }

          button {
            position: absolute;
            background-color: black;
            color: #fff;
            right: 0;
            height: 44px;
            border-bottom-right-radius: 8px;
            border-top-right-radius: 8px;
            z-index: 99;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            padding: 0 16px;
          }
        }
      }

      .all-cards {
        padding: 16px 0;
      }

      .ant-card-body {
        padding: 15px;

        @include md {
          padding: 24px;
        }
      }
    }
  }

  .active-content {
    width: calc(100% - 290px);
  }

  .inactive-content {
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .ant-card-body {
      > .ant-row {
        // align-items: center;
        .ant-col {
          > .ant-row {
            // align-items: center;
          }
        }
      }
    }
  }

  .collapse-content {
    width: calc(100% - 290px);
  }

  .rightSider {
    background-color: #ffffff;
    padding: 0 10px;
    position: absolute;
    width: 290px;
    height: 100%;
    right: 0;
    top: 0;
    transition: 0.6s;

    .empty_notification {
      text-align: center;
      color: #a7a6a6;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .right-silder-title {
      margin-top: 10px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
    }

    .rightSiderBox {
      margin-top: 10px;
      padding: 6px 7px;
      background: #f1f1f1;
      cursor: pointer;
    }

    .rightSiderBox:active,
    .rightSiderBox:hover {
      background-color: #d0d0d0;
    }

    .rightSiderOnlyText {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
    }
  }
}

.ant-menu-vertical-left {
  border-right: 1px solid #fff;
}

.openDrawer {
  .form-upload-container {
    .form-inside-container {
      > div {
        align-items: flex-start;
      }
    }
  }
}

.ant-drawer-close {
  position: relative;
  right: -30px;

  @include md {
    right: -60px;
  }
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  .ant-menu-item a {
    color: tint($primary-color, 20%);
    font-size: 14px;
    font-weight: 400;

    &:hover {
      color: $txt-color;
    }
  }

  .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
  .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
  .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
    border-right: 0;
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: 3px solid #1890ff;
    transform: scaleY(0.0001);
    opacity: 0;
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
      opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: "";
  }
}

.ant-menu-inline-collapsed-tooltip {
  .ant-tooltip-inner {
    background-color: $primary-color;
  }

  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(
      to right bottom,
      rgba(0, 27, 55, 0.9),
      rgba(0, 27, 55, 1)
    );
  }
}

// ant-notification
.ant-notification {
  top: 0 !important;
}

.common_table {
  .table_no_record {
    position: fixed;
    left: 50%;
    transform: translateY(50%);
    background-color: #ffffff !important;
  }
}

/**********Tabs css*****************/

.account_tabs_panel_left .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 1.2rem;

  @include customMinWidthBreakpoint(1024px) {
    margin: 0 0 0 1.5rem;
  }
}

.ant-tabs-tab:hover {
  color: unset !important;
}

.ant-tabs-tab-btn:focus {
  color: unset !important;
}
.ant-tabs-content-holder {
  .ant-tabs-content {
    position: unset;
  }
}
.account_tabs_panel_left {
  // position: relative;

  // margin: 0 16px;
  .ant-tabs-tab {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    margin: 0 0 0 0;
    padding: 12px 0px 8px;
    border-bottom: 2px solid transparent;

    @include xl {
      font-size: 16px;
    }

    @include customMinWidthBreakpoint(1024px) {
      margin: 0 0 0 1.5rem;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid $primary-color !important;
    .ant-tabs-tab-btn {
      color: rgb(0, 0, 0) !important;
      position: relative;
    }
  }

  .img {
    align-self: center;
  }

  .card_download {
    /* padding: 0 2rem 0 0; */
    margin-right: 2rem;
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    top: 0;
    right: 0;
    cursor: pointer;

    @include md {
      align-self: center;
    }

    .download-text {
      span {
        padding-left: 8px;
        margin: 0 !important;
        align-self: center;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #323232;
      }
    }
    .custom-button-link.style-1 {
      font-size: 12px !important;
      @include xl {
        font-size: 14px !important;
      }
    }
  }

  .cadsExtraTabs {
    display: flex;

    .mark-as-approved {
      .mark-as-approved-btn {
        .ant-btn {
          border: none;
          font-weight: 600;
          margin-right: 10px;
          font-size: 12px;
          padding: 0px 15px;
          @include customMinWidthBreakpoint(1024px) {
            font-size: 14px;
            padding: 4px 15px;
          }
        }

        .ant-btn .p {
          margin-bottom: 0;
        }
      }
    }

    .mark-as-archived-expense {
      .mark-as-archived-btn {
        .ant-btn {
          border: none;
          font-weight: 600;
          margin-right: 10px;
          font-size: 12px;
          padding: 0px 15px;
          @include customMinWidthBreakpoint(1024px) {
            font-size: 14px;
            padding: 4px 15px;
          }
        }
        .ant-btn[disabled] {
          background-color: transparent !important;
          border: none !important;
        }

        .ant-btn .p {
          margin-bottom: 0;
        }
      }
    }
  }

  .a:last-child {
    margin-right: unset !important;
  }

  .ant-tabs-nav {
    background: white;
    margin: 0;

    ::before {
      box-shadow: none !important;
    }
    ::after {
      box-shadow: none !important;
    }
  }

  .tab {
    margin: 0px;
    padding: 1rem 2rem 0rem 2rem;
    background: rgb(255, 255, 255);
    position: relative;
    border-bottom: 1px solid #d9d9d9;

    .tabView {
      display: flex;
      border: none;

      .active {
        color: rgb(0, 0, 0);
      }

      a {
        margin-right: 68px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #a6a6a6;
        padding-bottom: 11px;
      }
    }
  }

  .ant-tabs-ink-bar {
    background: #ee6c4d !important;
    height: 0 !important;
    margin-top: 12px;
  }

  .ant-tabs-nav-wrap {
    // margin-left: 2rem;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #a6a6a6;
    // margin-left: 1.5rem;
    .ant-tabs-nav-list {
      transform: translate(0) !important;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
}

.ant-tabs-nav {
  margin: 0 !important;
}

/********************Top Filter section button******************/
.new-payment-button {
  border-radius: 10px;
  /* width: 187px; */
  /* margin-left: 10px; */
  /* height: 40px; */
  border: 2px solid;
  cursor: pointer;
  color: #1127ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1.44rem;
}

.new-payment-button:hover {
  background-color: #1127ff;
  transition: 0.3s;
  color: #fff;
  border: solid 2px #1127ff;
}

// <-------------- status tag --------------->

.common_table .dataTable tbody tr th span::first-letter {
  text-transform: uppercase;
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu {
  li {
    display: flex;
    margin-top: 0;
    margin-bottom: 3px;

    > span {
      display: flex;
      align-items: center;
    }
  }
}

aside
  .ant-menu-vertical.ant-menu-inline-collapsed
  .ant-menu-submenu-selected
  > div {
  background-color: $brdcolSecondary;
  span {
    color: $txt-color;
  }
}

.ant-dropdown-menu-vertical .ant-dropdown-menu-item-selected {
  background-color: $brdcolSecondary;
  color: $txt-color !important;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: none !important;
}

// <-------------- helper class --------------->

.mt-10 {
  margin-top: 10px;
}

// <-------------- Modal popup class --------------->

.idle-timer-modal .ant-modal-content {
  border-radius: 12px;
  height: 212px;
}

.idle-timer-modal .idle-timer-modal-container .confirm-close-button {
  display: flex;
  justify-content: space-between;
}

.idle-timer-modal .ant-modal-content p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #232325;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.idle-timer-modal .ant-modal-content .ant-modal-body {
  height: 132px;
  align-items: unset !important;
  justify-content: unset !important;
}

.idle-timer-modal .ant-modal-content .ant-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 40px;
  gap: 24px;
  // width: 520px;
  height: 80px;
  background: #f7f7f9;
  border-radius: 0px 0px 12px 12px;
  flex: none;
  border-top: 0;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.idle-timer-modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;
  width: 133px;
  height: 48px;
  background: #1127ff;
  border-radius: 8px;
  border: none;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.idle-timer-modal .ant-modal-content .ant-modal-footer .ant-btn-default {
  color: #1127ff;
  font-style: normal;
  padding: 0;
  border: none;
  background: none;
  font-weight: 700;
  font-size: 16px;
  height: 48px;
  line-height: 24px;
  box-shadow: none;
}

.ant-notification-notice {
  width: auto !important;
}

.approvals .ant-tabs-content {
  position: static;
}

.approvals .account_tabs_panel_left {
  position: static;
  .ant-tabs-nav {
    padding: 0 15px;
    @include md {
      padding: unset;
    }
  }
}

// #l.andscapeLock-wraper {
//   display: none;
// }

.explanatory-text {
  margin-top: 15px;
  .custom-button-link {
    display: inline-block !important;
  }
  &.visible-desktop {
    display: none;
    @include lg {
      display: block;
    }
  }
  &.hidden-desktop {
    @include lg {
      display: none;
    }
  }

  &.mb-1 {
    margin-bottom: 1rem;
  }
}

// <-------------- Custom buttons --------------->

.custom-button {
  display: flex !important;
  text-decoration: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  align-items: center !important;
  padding: 8px 15px !important;
  line-height: 24px !important;
  justify-content: center !important;
  min-height: 42px !important;
  cursor: pointer !important;
  font-size: 14px !important;
  height: auto !important;
  border-color: transparent !important;
  box-shadow: none !important;
  span {
    font-size: 14px !important;
  }
  i {
    font-weight: 400 !important;
    font-size: 24px !important;
  }
  i + span {
    margin-left: 8px !important;
  }
  span + i {
    margin-left: 8px !important;
  }
  &:disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
  }
  &.style-1 {
    background: $brdcolSecondary !important;
    border: 1px solid $brdcolSecondary !important;
    color: $primary-color !important;
    i {
      color: inherit !important;
    }
    span {
      color: inherit !important;
    }
    &:hover,
    &:focus,
    &:active {
      background: $brdcolSecondary !important;
      border: 1px solid $brdcolSecondary !important;
      i {
        color: inherit !important;
      }
    }
  }
  &.style-2 {
    color: #1127ff !important;
    border: 1px solid #1127ff !important;
    background: #fff !important;
    i {
      color: inherit !important;
    }
    span {
      color: inherit !important;
    }
    &:hover,
    &:focus,
    &:active {
      // background: #fff !important;
      border: 1px solid #1127ff !important;
      i {
        color: inherit !important;
      }
    }
  }
  &.style-3 {
    color: #fff !important;
    border: 1px solid #ee6c4d !important;
    background: #ee6c4d !important;
    i {
      color: inherit !important;
    }
    span {
      color: inherit !important;
    }
    &:hover,
    &:focus,
    &:active {
      background: #ee6c4d !important;
      border: 1px solid #ee6c4d !important;
      i {
        color: inherit !important;
      }
    }
  }
  &.style-4 {
    color: #fff !important;
    border: 1px solid #249f5d !important;
    background: #249f5d !important;
    i {
      color: inherit !important;
    }
    span {
      color: inherit !important;
    }
    &:hover,
    &:focus,
    &:active {
      background: #249f5d !important;
      border: 1px solid #249f5d !important;
      i {
        color: inherit !important;
      }
    }
  }
  &.style-5 {
    color: #fff !important;
    border: 1px solid #f32d2d !important;
    background: #f32d2d !important;
    i {
      color: inherit !important;
    }
    span {
      color: inherit !important;
    }
    &:hover,
    &:focus,
    &:active {
      background: #f32d2d !important;
      border: 1px solid #f32d2d !important;
      i {
        color: inherit !important;
      }
    }
  }
  &.style-6 {
    color: #eb5757 !important;
    border: 1px solid #eb5757 !important;
    background: #fff !important;
    i {
      color: inherit !important;
    }
    span {
      color: inherit !important;
    }
    &:hover,
    &:focus,
    &:active {
      border: 1px solid #eb5757 !important;
      i {
        color: inherit !important;
      }
    }
  }
  &.style-7 {
    color: #000 !important;
    border: 1px solid #e0e2e7 !important;
    border-radius: 20px !important;
    background: #fff !important;
    i {
      color: inherit !important;
    }
    span {
      color: inherit !important;
    }
    &:hover,
    &:focus,
    &:active {
      border: 1px solid #e0e2e7 !important;
      i {
        color: inherit !important;
      }
    }
  }
}

.custom-button-link {
  display: flex !important;
  text-decoration: none !important;
  font-weight: 500 !important;
  align-items: center !important;
  justify-content: flex-start !important;
  cursor: pointer !important;
  padding: 0 !important;
  background-color: transparent !important;
  background: transparent !important;
  border: 0 !important;
  // font-size: 14px !important;
  // span {
  //   font-size: 14px !important;
  // }
  i {
    font-weight: 400 !important;
    font-size: 18px !important;
  }
  i + span {
    margin-left: 8px !important;
  }
  span + i {
    margin-left: 8px !important;
  }
  &:disabled {
    opacity: 0.5;
  }
  &.style-1 {
    color: #1127ff !important;
    i {
      color: inherit !important;
    }
    span {
      color: inherit !important;
    }
    &:hover,
    &:focus,
    &:active {
      color: #1127ff !important;
      i {
        color: inherit !important;
      }
    }
  }
}

/*Dynamic helper css*/
@for $size from 0 through 100 {
  @each $unit, $ut in $unit {
    @each $position, $pos in $position {
      .m-#{$pos}-#{$size}#{$unit} {
        margin-#{$position}: #{$size}#{$ut} !important;
      }
      .p-#{$pos}-#{$size}#{$unit} {
        padding-#{$position}: #{$size}#{$ut} !important;
      }
    }

    .m-#{$size}#{$unit} {
      margin: #{$size}#{$ut} !important;
    }
    .m-v-#{$size}#{$unit} {
      margin-top: #{$size}#{$ut} !important;
      margin-bottom: #{$size}#{$ut} !important;
    }
    .m-h-#{$size}#{$unit} {
      margin-right: #{$size}#{$ut} !important;
      margin-left: #{$size}#{$ut} !important;
    }

    .p-#{$size}#{$unit} {
      padding: #{$size}#{$ut} !important;
    }
    .p-v-#{$size}#{$unit} {
      padding-top: #{$size}#{$ut} !important;
      padding-bottom: #{$size}#{$ut} !important;
    }
    .p-h-#{$size}#{$unit} {
      padding-right: #{$size}#{$ut} !important;
      padding-left: #{$size}#{$ut} !important;
    }

    .w-#{$size}#{$unit} {
      width: #{$size}#{$ut} !important;
    }
    .h-#{$size}#{$unit} {
      height: #{$size}#{$ut} !important;
    }
    .t-#{$size}#{$unit} {
      top: #{$size}#{$ut} !important;
    }
    .l-#{$size}#{$unit} {
      left: #{$size}#{$ut} !important;
    }
    .b-#{$size}#{$unit} {
      bottom: #{$size}#{$ut} !important;
    }
    .r-#{$size}#{$unit} {
      right: #{$size}#{$ut} !important;
    }
    .fs-#{$size}#{$unit} {
      font-size: #{$size}#{$ut} !important;
    }
  }
}
.swicth-modal {
  .switch-profile-wrap {
    margin: 0;
    padding: 0;
    border: 0;
    h3 {
      @include customMaxWidthBreakpoint(767px) {
        font-size: 1.2rem;
      }
    }
  }
}
.textarea-with-prefix-icon {
  position: relative;
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    z-index: 0;
  }
  &.heart-icon {
    textarea {
      padding-left: 39px;
      line-height: 2.1;
      min-height: 45px;
      font-size: 14px;
    }
    .prefix-icon {
      position: absolute;
      left: 12px;
      top: 11px;
      z-index: 1;
    }
  }
}

.width-full {
  width: 100%;
}

.h-400 {
  height: 400px !important;
}

.card-limit-info {
  background-color: #57b2f2;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: justify;
}

.optional-field {
  border-color: $optionalField;
}

.text-align-right {
  text-align: right;
}

.historySearch .ant-input-affix-wrapper {
  height: 44px;
  border: 1px solid #000;
  border-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-tabs-nav-operations {
  display: none !important;
}

.custom-text-optional-select.commonSelect:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: #d9d9d9 !important;
  &:hover {
    border-color: #2712ff !important;
  }
  &.ant-select-disabled:hover {
    border-color: #d9d9d9 !important;
  }
}
.custom-text-optional-select.commonSelect:not(
    .ant-select-customize-input
  ).ant-select-disabled:hover
  .ant-select-selector {
  border-color: #d9d9d9 !important;
}

.kloo-layout-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.kloo-layout-table {
  flex-grow: 1;
  background-color: #fff;
  overflow: auto;
}

.red-color {
  color: $destructive;
}

.green-color {
  color: #0db609;
}

.brand-color {
  color: $brdcolSecondary;
}

.primary-color {
  color: $primary-color;
}

.txt-color {
  color: $txt-color;
}

.profile-dropdown {
  min-width: 100px;
}

.h-auto {
  height: auto;
}

.kloo-disabled {
  background-color: $kloo-disabled-bg !important;
  cursor: not-allowed !important;
  &:hover {
    background-color: $kloo-disabled-bg !important;
  }
}

.kloo-popover {
  .ant-popover-inner-content {
    padding: 0px !important;
  }
  .ant-popover-title {
    color: $white !important;
    border-bottom: none !important;
    padding: 6px 8px !important;
    min-width: 100% !important;
  }
}
