.item-list-container {
  table {
    border-collapse: collapse;
    table-layout: auto !important;
  }
  th,
  td {
    white-space: nowrap;
  }
}
