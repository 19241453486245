$background-color: white;

.yapily-terms-footer {
  text-align: center;
}

.yapily-landing-page {
  height: 100%;
  background-color: $background-color;
}
