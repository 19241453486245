@import "../../../assets/scss/customVariables";

.workflow-automation {
  .select-automation {
    width: 410px;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .mapping-workflow-row {
    align-items: center;
    margin-top: 16px;
  }
}

.view-more {
  color: #1127ff;
  cursor: pointer;
  p {
    margin-bottom: 0px;
  }
}
.top-panel {
  .automation-switch-disable {
    opacity: 0.6;
  }
}
.clear-mapping {
  color: $primary-color !important;
}
.clear-selection-remaining {
  cursor: pointer;
}

.m-b-0px {
  .form-item-margin {
    margin-bottom: 0px !important;
  }
}

.automation-item-border {
  border: $card-border solid 2px;
  border-radius: 4px;
  padding: 16px;
}
