@import "../../../../assets/scss/customVariables";

.line-items-section {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
  .line-items-table-body {
    max-height: 80%;
    overflow: auto;
    flex: 1;
    table {
      border-collapse: collapse;
      table-layout: auto;
      th,
      td {
        white-space: nowrap;
      }
    }
  }
  .sage-intacct-column {
    color: $sage-card-text;
  }
  .xero-column {
    color: $xero-card-text;
  }
  .dyanmic-erp-column {
    width: 100%;
  }
  .common_table {
    scrollbar-width: auto;
    .dataTable tr td {
      vertical-align: top;
    }
    .dataTable tfoot tr {
      display: table-row;
      td {
        vertical-align: baseline;
      }
    }
  }
  tbody:after {
    line-height: 1em;
    content: "";
    padding-right: 1px;
  }
  .line-item-qty,
  .line-item-unit-cost {
    padding: 7px !important;
  }
  .table-cell-display-value {
    margin-top: 8px;
  }
  .ant-form-item-explain-error {
    white-space: break-spaces;
  }
  .gray-bg {
    background: #f0f2f5;
  }
}

.hidden {
  display: none;
}

.custom-field-width {
  width: 200px;
}
