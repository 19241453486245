@import "../../../../assets/scss/customVariables";

.line-items-section {
  z-index: 1;
  background: $white;
  position: relative;
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  .header {
    color: $white;
    padding-inline: 20px;
    padding-block: 10px;
  }

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .row-header {
    padding-inline: 20px;
    padding-block: 10px;
    width: 100%;
  }

  .arrow-icon {
    font-size: 30px;
    cursor: pointer;
  }

  .detail-drawer {
    position: relative;
    border-left: 1px solid $collapsible-drawer-border-color;
  }

  .border-icon {
    position: absolute;
    top: 40%;
    left: -15px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $white;
    box-shadow: 1px 2px 6px 2px $drawer-collapse-icon-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .border-icon-right {
    position: absolute;
    top: 40%;
    right: 0;
    transform: translateY(-50%);
  }

  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: $white;
    box-shadow: 1px 2px 6px 2px $drawer-collapse-icon-color;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close-icon {
    color: #1c1b1f;
    cursor: pointer;
  }
  .invoice-icon-title {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  .overflow-y-scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }
}
