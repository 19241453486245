@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.item-table-card {
  .common_table {
    height: auto;
    margin-bottom: 0px;
    tbody {
      tr {
        cursor: default;
        td:last-child {
          // background-color: $white;
        }
      }
    }
  }
  .ant-card-body {
    padding: 0px;
  }

  .item-table-form {
    width: 100%;
    // button {
    //   min-width: 122px;
    // }
  }
}

.purchase-order-drawer {
  .ant-form-item-label label {
    font-size: 16px;
  }

  .ant-input-number-input {
    padding: 0;
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;

    @include md {
      width: 36rem !important;
    }
  }

  .commonSelect {
    .ant-select-arrow,
    .ant-select-clear {
      font-size: 14px;
      width: 14px;
      height: 14px;
    }
  }

  .commonDatePicker .ant-picker-suffix {
    color: rgba($brdcolSecondary, 1);

    .material-icons {
      font-size: 22px;
    }
  }

  .item-table-card {
    .po-table-listing {
      .item-list-Po-input::before {
        height: 48px !important;
        border: 2px solid $brdcolSecondary !important;
        border-radius: 4px;
      }
    }
  }
  .currency-total-po {
    .title-form {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #000000;
      padding: 0 0 8px;
    }

    .total-gross-amount {
      .ant-form-item {
        .ant-form-item-row {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              .commonInputNumber {
                padding: 0px 14px;
              }
            }
          }
        }
      }
    }

    .ant-form-item {
      .ant-form-item-row {
        .ant-form-item-control {
          .ant-form-item-control-input {
            .commonDatePicker {
              height: 52px !important;
            }
          }
        }
      }
    }
  }

  // .commmon-use-on-switch {
  //   .ant-form-item-row {
  //     .ant-form-item-control {
  //       .ant-form-item-control-input {
  //         .ant-form-item-control-input-content {
  //           .ant-switch {
  //             border: 2px solid $grey5;
  //             background-color: $grey6;

  //             .ant-switch-handle {
  //               &::before {
  //                 background-color: $grey5;
  //                 box-shadow: none;
  //               }
  //             }
  //           }

  //           .ant-switch-checked {
  //             border: 2px solid $brandcolor;
  //             background-color: $brandcolor;
  //             .ant-switch-handle::before {
  //               background-color: $white;
  //               box-shadow: none;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
.purchase-order-drawer-v1 {
  .ant-drawer-content-wrapper {
    width: 100% !important;

    @include md {
      width: calc(100% - 215px) !important;
    }
  }

  .ant-drawer-footer {
    background: $drawer-footer-bg;
  }
  .invoice-drawer-tracking-categories-section {
    border: 1px solid $grey;
    border-radius: 4px;
    .prefix-icon {
      border-radius: 4px 0px 0px 4px;
      border: 2px solid tint($txt-color, 50%);
      border-right: none !important;
      background-color: #f2f3f4;
    }
  }
  .ant-drawer-body {
    margin-bottom: 60px;
    padding-bottom: 0;
  }
  .purchase-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.asterisk-container {
  position: unset !important;
}
