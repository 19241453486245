.custom-text-optional {
  border-color: #d9d9d9;
  &:hover {
    border-color: #2712ff;
  }
}

.custom-text-optional.ant-input-disabled {
  border-color: #d9d9d9;
  &:hover {
    border-color: #d9d9d9;
  }
}
