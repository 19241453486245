@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.kloo-upload-container {
  .ant-upload {
    display: flex;
    height: 100%;
    width: 100%;
    .material-icons-outlined {
      font-size: 40px;
    }
  }
  .ant-upload-list-text-container {
    .ant-upload-list-item {
      margin-bottom: 10px;
    }
  }
}
