@import "../../../assets/scss/customVariables";

.preview-workflow-section {
  border: 1px solid $grey;
  border-radius: 4px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(228, 228, 228);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 20px;
  }
  .approver {
    width: 103px;
  }
  .approver-divider {
    white-space: nowrap;
    font-size: x-large;
  }
}
