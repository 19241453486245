@import "../../../assets/scss/_customVariables.scss";

.carousel-step {
}

// .carousel-step-tooltip {
//   .ant-tooltip-arrow-content {
//     --antd-arrow-background-color: white;
//   }
//   .ant-tooltip-inner {
//     color: black;
//     background-color: white;
//   }
// }

.step-box-carousel {
  min-width: 100px;
  min-height: 40px;
  border: 2px solid #9ca1ad;
  border-radius: 5px;
  &.active {
    border-color: $brdcolSecondary;
  }
  .step-content-carousel {
    display: flex;
  }
  .step-content-carousel-hover {
    display: none;
  }
}

.step-box-carousel-first {
  min-width: 100px;
  min-height: 50px;
  border: 2px solid #9ca1ad;
  border-radius: 5px;
  &.active {
    border-color: $brdcolSecondary;
  }
}

.step-box-carousel:hover {
  .step-content-carousel-hover {
    display: flex;
  }
  .step-content-carousel {
    display: none;
  }
}

.carousel-step-carousel {
  max-width: 100%;
  overflow: auto;
  padding-bottom: 5px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgb(228, 228, 228);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 20px;
  }
}
