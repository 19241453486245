.kloo-embedded-iframe-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  .kloo-embedded-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: 2px 2px 6px 0px rgb(0 0 0 / 25%);
  }
}
