@import "../../../assets/scss/mixins";
@import "../../../assets/scss/customVariables";

.review-purchase-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
    @include xl {
      width: 650px !important;
    }
  }

  &.expanded-version {
    .ant-drawer-content-wrapper {
      width: 100% !important;
      @include xl {
        width: calc(100% - 15px) !important;
      }
    }
  }

  .ant-card-bordered.pdf-view-container {
    border-width: 2px;
  }

  Button.remove-list {
    float: right;
  }

  .common_table {
    border: solid 1px $grey;
    border-radius: 4px;
    padding: 0 16px 16px 16px;
    height: auto;
    // @include lg {
    //   height: calc(100vh - 257px);
    // }
  }
  .purchase-drawer-footer {
    background-color: $lightgrey;
    position: absolute;
    z-index: 100;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
  }
}
