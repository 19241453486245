@use "sass:math";
@use "sass:color";
@import "../../../assets/scss/customVariables";

// base padding
$baseTagPadding: 1rem;

// source map for tag text color
$tag: (
  "active": #66ddd6,
  "used": #6300ff,
  "rejected": #ff0055,
  "awaiting-approval": #ffbc00,
  "inactive": #6c6f73,
  "created": #001b37,
  "refunded": #66ddd6,
  "on-hold": #0095fa,
);

.kloo-tag {
  // base property of tag
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  border: none !important;
  border-radius: 4px !important;
  border: 0 !important;
  display: inline-block !important;
  margin: 0 !important;
  white-space: normal !important;
  word-break: normal !important;
  padding: math.div($baseTagPadding, 8) $baseTagPadding !important; // top-bottom padding divided by 8 & right-left kept as is
  @each $tagName, $tagColor in $tag {
    // looping through tag map to generate color & background color automatically
    &.#{$tagName} {
      color: shade($tagColor, 20%) !important;
      // only lighness changed in hsl value so background appears lighter than text
      background: tint($tagColor, 90%) !important;
    }
  }
}
