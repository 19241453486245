$brdcolSecondary: #66ddd6;

.institution-account-card {
  width: 250px;
  height: 150px;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    border-color: $brdcolSecondary;
  }

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .yapily-institution-account-icon .kloo-image {
    max-width: 150px;
    max-height: 100px;
  }

  .yapily-institution-account-text {
    max-width: 200px;
  }
}

.yapily-authentication-page {
  .yapily-qr-institution-login-container {
    height: 280px;
  }

  .yapily-relaunch-login {
    height: 302px;
  }

  .ant-card-actions {
    padding-left: 15px;
    border: 0;
  }

  &.centered-page {
    width: 500px;
    margin: 0 auto;
  }
}
