@import "../../assets/scss/mixins";
.notifications-popover {
  width: 35vw;
  @include customMaxWidthBreakpoint(1020px) {
    width: 47vw !important;
  }
  @include customMaxWidthBreakpoint(768px) {
    width: 67vw !important;
  }
  @include customMaxWidthBreakpoint(600px) {
    width: 96vw !important;
  }
  top: 68px !important;
  .ant-popover-inner {
    border-radius: 8px;
    .ant-popover-title {
      h1 {
        margin-bottom: 0;
      }
    }
    .ant-popover-inner-content {
      max-height: calc(100vh - 150px);
      overflow: auto;
    }
    .ant-popover-inner-content::-webkit-scrollbar {
      width: 8px;
    }

    .ant-popover-inner-content::-webkit-scrollbar-track {
      background: rgb(228, 228, 228);
      border-radius: 20px;
    }

    .ant-popover-inner-content::-webkit-scrollbar-thumb {
      background-color: #bdbdbd;
      border-radius: 20px;
    }
  }
}

.notification-alert-banner-section {
  @include customMaxWidthBreakpoint(768px) {
    margin-left: 0 !important;
  }
  // margin-left: -16.3rem;
  z-index: 100;
  .ant-alert-warning {
    background-color: #fff6c5;
    min-height: 50px;
    .ant-alert-content {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }
}
